import { useParams } from "react-router-dom";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { MypageShipmentType } from "@sellernote/_shared/src/types/forwarding/bid";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";
import { getInvoiceTypeKR } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";

import TradingStatementInfo from "./TradingStatementInfo";

const TradingStatement = () => {
  const invoiceId =
    sessionStorage.getItem("invoiceId") !== "none"
      ? Number(sessionStorage.getItem("invoiceId"))
      : undefined;

  const { bidId, invoiceType, shipmentType } = useParams<{
    bidId: string;
    invoiceType: InvoiceType;
    shipmentType: MypageShipmentType;
  }>();

  const { data: bidDetailData } = TRELLO_BID_QUERY.useGetTrelloBidDetail({
    bidId: Number(bidId),
    enabled: shipmentType === "import",
  });

  const { data: exportShipmentDetailData } =
    TRELLO_BID_QUERY.useGetExportTrelloDetail({
      bidId: Number(bidId),
      enabled: shipmentType === "export",
    });

  const { data: portData } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: countryList } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { data: invoiceData } = ADMIN_BID_QUERY.useGetAdminInvoice({
    invoiceId,
  });

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: bidDetailData?.locale,
  });

  const shipmentDetailData =
    shipmentType === "export" ? exportShipmentDetailData : bidDetailData;

  if (
    !shipmentDetailData ||
    !portData ||
    !countryList ||
    (invoiceId && !invoiceData) ||
    !exchangeRateList
  ) {
    return (
      <Layout breadcrumbs={["입찰 관리"]} title={"거래명세서 생성"}>
        <Loading active={true}></Loading>
      </Layout>
    );
  }

  return (
    <Layout
      breadcrumbs={["입찰 관리"]}
      title={`${getInvoiceTypeKR(invoiceType)} 생성 ${bidId}`}
    >
      <TradingStatementInfo
        bidDetailData={shipmentDetailData}
        portData={portData}
        countryList={countryList}
        invoiceData={invoiceData}
        exchangeRateList={exchangeRateList}
      />
    </Layout>
  );
};

export default TradingStatement;
