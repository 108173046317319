import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { Country, Port } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getBidDataCbmOrWeight,
  getCbmOfInvoiceData,
  getDefaultFeeData,
  getInvoiceDataCbm,
  getInvoiceDataWeight,
  getInvoiceExchangeRateList,
  getPartnerNameOrBRNNumber,
  getTradingStatementArrivalData,
  getTradingStatementComment,
  getTradingStatementCountryName,
  getTradingStatementPortName,
  getWeightOfInvoiceData,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import { getQuotationsUserForTradingStatement } from "@sellernote/_shared/src/utils/forwarding/trello";
import QuotationFeeForm from "@sellernote/_shared-for-forwarding-admin/src/components/QuotationFeeForm";

import ExchangeDesc from "./ExchangeDesc";
import RequestInvoice from "./RequestInvoice";
import TradingStateDescriptions from "./TradingStateDescriptions";

const TradingStatementInfo = ({
  bidDetailData,
  portData,
  countryList,
  invoiceData,
  exchangeRateList,
}: {
  bidDetailData: TrelloBidDetail;
  portData: Port[];
  countryList: Country[];
  invoiceData: InvoiceData | undefined;
  exchangeRateList: ExchangeRate[];
}) => {
  const { invoiceType } = useParams<{
    invoiceType: InvoiceType;
  }>();

  /**
   *  SG쉽다 거래명세서 코멘트는 quotationsUser[] > status가 succeed인 항목의 comment가 보여야함 (AB12722)
   */
  const tradingStatementComment = (() => {
    if (bidDetailData.locale === "SG") {
      return bidDetailData.quotationsUser.find(
        (quote) => quote.status === "succeed"
      )?.comment;
    }

    return invoiceData
      ? invoiceData.comment
      : getTradingStatementComment({
          invoiceType,
          serviceType: bidDetailData.serviceType,
        });
  })();

  // 확정견적과, 사용한 환율 데이터, 폼에서 사용할 데이터를 만든다.
  const { quotationsUser, exchangeRateData, quotationsUserFeeData } =
    useMemo(() => {
      return getQuotationsUserForTradingStatement(
        bidDetailData,
        exchangeRateList
      );
    }, [bidDetailData, exchangeRateList]);

  // 확정견적 아이템을 만든다.
  const { data: applyItems } = ADMIN_BID_QUERY.useGetAdminBidApplyItems({});

  // 폼 데이터를 셋팅한다.
  const { control, watch, setValue } = useForm<ApplyBidFormData>({
    defaultValues: {
      // invoiceType과 InvoiceData에 따라 기본 폼 데이터를 만들어 준다.
      domesticFeeData: getDefaultFeeData({
        invoiceType,
        invoiceData,
        feeDataType: "domesticFee",
        quotationsUserFeeData,
      }),
      freightFeeData: getDefaultFeeData({
        invoiceType,
        invoiceData,
        feeDataType: "freightFee",
        quotationsUserFeeData,
      }),
      localFeeData: getDefaultFeeData({
        invoiceType,
        invoiceData,
        feeDataType: "localFee",
        quotationsUserFeeData,
      }),
      otherFeeData: getDefaultFeeData({
        invoiceType,
        invoiceData,
        feeDataType: "otherFee",
        quotationsUserFeeData,
      }),
      taxFeeData: getDefaultFeeData({
        invoiceType,
        invoiceData,
        feeDataType: "taxFee",
        quotationsUserFeeData,
      }),
      inlandFeeData: getDefaultFeeData({
        invoiceType,
        invoiceData,
        feeDataType: "inlandFee",
        quotationsUserFeeData,
      }),
      arrivalDate: getTradingStatementArrivalData(
        invoiceData,
        bidDetailData.management
      ),
      comment: tradingStatementComment,
      startPort: getTradingStatementPortName(
        quotationsUser.startPortId,
        portData
      ),
      endPort: getTradingStatementPortName(quotationsUser.endPortId, portData),
      cbm: invoiceData
        ? getCbmOfInvoiceData(invoiceData.cbm)
        : bidDetailData.management.invoiceCbm
        ? getInvoiceDataCbm(bidDetailData.management.invoiceCbm)
        : getBidDataCbmOrWeight(bidDetailData, "cbm"),
      ton: invoiceData
        ? getWeightOfInvoiceData(invoiceData.cbm, invoiceData.freightType)
        : bidDetailData.management.invoiceCbm
        ? getInvoiceDataWeight(
            bidDetailData.management.invoiceCbm,
            bidDetailData.freightType
          )
        : getBidDataCbmOrWeight(bidDetailData, "weight"),
      blNumber:
        bidDetailData.management.BLType === "MBL" ||
        bidDetailData.management.BLType === "DirectMBL"
          ? bidDetailData.management.mBL
          : bidDetailData.management.hBL,
      packageValue: invoiceData
        ? invoiceData.package
        : bidDetailData.management.package
        ? bidDetailData.management.package
        : "",
      startCountryName: getTradingStatementCountryName(
        countryList,
        bidDetailData.startCountry
      ),
      endCountryName: getTradingStatementCountryName(
        countryList,
        bidDetailData.endCountry
      ),
      voyageNo: bidDetailData.management.voyageNumber
        ? bidDetailData.management.voyageNumber
        : "-",
      shipName: bidDetailData.management.shipName
        ? bidDetailData.management.shipName
        : "",
      invoiceExchangeRateList: getInvoiceExchangeRateList({
        invoiceType,
        exchangeRateData,
        invoiceData,
      }),
      todayExchangeRateList: exchangeRateData,
      checkDisabled: true,
      recipient: invoiceData
        ? invoiceData.recipient
        : getPartnerNameOrBRNNumber(invoiceType, bidDetailData, "name"),
      userBRN: invoiceData
        ? invoiceData.BRN
        : getPartnerNameOrBRNNumber(invoiceType, bidDetailData, "BRNNumber"),
      containersNumberInfo: bidDetailData.isImport
        ? invoiceData?.containersNumberInfo?.map((v) => {
            return {
              containersNumber: v,
            };
          }) ?? []
        : bidDetailData.containersNumberInfo?.map((v) => {
            return {
              containersNumber: v,
            };
          }) ?? [],
    },
  });

  const [requestButtonDisabledValue, setRequestButtonDisabledValue] =
    useState(true);

  // AIR 의뢰인 경우 Rton을 직접 입력한다.
  const [directAirRton, setDirectAirRton] = useState<number | undefined>(
    bidDetailData.freightType === "AIR" && invoiceData?.rton
      ? Number(invoiceData?.rton)
      : undefined
  );

  const handleDirectAirRtonChange = (rton: number | undefined) => [
    setDirectAirRton(rton),
  ];

  return (
    <Grid container direction={"column"} spacing={2}>
      <Grid item>
        <RequestInvoice
          invoiceData={invoiceData}
          bidDetailData={bidDetailData}
          requestButtonDisabledValue={requestButtonDisabledValue}
          invoiceState={watch()}
          directAirRton={directAirRton}
        />
      </Grid>

      <Grid item>
        <ExchangeDesc
          invoiceData={invoiceData}
          invoiceState={watch()}
          setValue={setValue}
        />
      </Grid>

      <Grid item>
        <TradingStateDescriptions
          control={control}
          invoiceState={watch()}
          setValue={setValue}
          portData={portData}
          setRequestButtonDisabledValue={setRequestButtonDisabledValue}
          bidDetailData={bidDetailData}
          directAirRton={directAirRton}
          handleDirectAirRtonChange={handleDirectAirRtonChange}
        />
      </Grid>

      <Grid item container gap={1}>
        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"localCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"localFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"freightCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"freightFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"domesticCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"domesticFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"inlandCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"inlandFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"otherCost"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"otherFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}

        {applyItems && (
          <QuotationFeeForm
            quotationFeeFormCategory={"tax"}
            watch={watch}
            setValue={setValue}
            templateType="bidApply"
            exchangeRateList={exchangeRateList}
            applyShipmentFormFeeDataType={"taxFeeData"}
            control={control}
            applyItems={applyItems}
            bidDetail={bidDetailData}
            directAirRton={directAirRton}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TradingStatementInfo;
