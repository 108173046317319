import React from "react";
import { List, Popover } from "antd";

import Styled from "./index.styles";

const ContainerList = ({ data }: { data: string[] }) => {
  const Content = (
    <Styled.popList>
      <List
        className="pop-list"
        size="small"
        grid={{ column: 2 }}
        dataSource={data}
        renderItem={(item) => <List.Item key={item}>{item}</List.Item>}
      />
    </Styled.popList>
  );

  return (
    <Styled.container>
      <Popover content={Content} placement="top" title="CNTR">
        <List
          size="small"
          grid={{ column: 2 }}
          dataSource={data}
          renderItem={(item) => <List.Item key={item}>{item}</List.Item>}
        />
      </Popover>
    </Styled.container>
  );
};

export default ContainerList;
