import { Dispatch, SetStateAction, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { InvoiceType } from "@sellernote/_shared/src/types/forwarding/trello";
import { getInvoiceTypeKR } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

function DeleteInvoiceModal({
  handleDeleteInvoiceModalClose,
  showsDeleteInvoiceModal,
  invoiceId,
  setShowsSuccessSnackBar,
  setShowsErrorSnackBar,
}: {
  handleDeleteInvoiceModalClose: () => void;
  showsDeleteInvoiceModal: boolean;
  invoiceId: number;
  setShowsSuccessSnackBar: Dispatch<SetStateAction<boolean>>;
  setShowsErrorSnackBar: Dispatch<SetStateAction<boolean>>;
}) {
  const { bidId, invoiceType } = useParams<{
    bidId: string;
    invoiceType: InvoiceType;
  }>();

  const { mutate: deleteInvoice } =
    ADMIN_FILE_QUERY.useDeleteInvoice(invoiceId);

  const handleInvoiceDelete = useCallback(() => {
    deleteInvoice(
      {},
      {
        onSuccess: () => {
          setShowsSuccessSnackBar(true);
          history.go(-1);
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  }, [deleteInvoice, setShowsErrorSnackBar, setShowsSuccessSnackBar]);

  return (
    <Modal
      handleClose={handleDeleteInvoiceModalClose}
      isOpened={showsDeleteInvoiceModal}
      modalBody={
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Typography
              variant="h6"
              component="span"
            >{`${bidId}의 ${getInvoiceTypeKR(invoiceType)}`}</Typography>

            <Typography variant="body1" component="span">
              을(를))
            </Typography>
          </Grid>

          <Grid item>
            <Typography>삭제하시겠습니까?</Typography>
          </Grid>

          <Grid item>
            <Button
              onClick={handleDeleteInvoiceModalClose}
              variant="text"
              sx={{ color: grey[900] }}
            >
              아니오
            </Button>
            /
            <Button
              onClick={handleInvoiceDelete}
              variant="text"
              color="primary"
            >
              제거
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}

export default DeleteInvoiceModal;
