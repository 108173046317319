import { message } from "antd";

export const error = () => {
  message.error("오류가 있습니다 개발자에게 문의해주세요");
};

export const requestExporterInfosuccess = () => {
  message.success("수입자에게 수출자 정보 요청을 보냈습니다.");
};

export const operatorSuccess = () => {
  message.success("운영 담당자를 등록했습니다");
};

export const salesSuccess = () => {
  message.success("담당자를 등록했습니다");
};

export const partnerSuccess = () => {
  message.success("파트너회사를 등록했습니다.");
};

export const warning = (text: any) => {
  message.warning(text);
};

export const success = (text: any) => {
  message.success(text);
};
