import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useAtom } from "jotai";

import { GET_ADMIN_NEW_BID_USER_REQ_SEARCH_KIND } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidUser";
import ADMIN_BID_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import { UserListItem } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useGetForwardingManagerName from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetForwardingManagerName";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import { TermSearchType } from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";
import useSearchWithTerm from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";
import useTableHeadSortFilter from "@sellernote/_shared-for-forwarding-admin/src/hooks/useTableHeadSortFilter";
import { FORWARDING_ADMIN_USER_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/user/index";

import ForwardingManagerFilter from "./ForwardingManagerFilter";

type CellId = keyof UserListItem;

const termSearchTypeOptions: TermSearchType<GET_ADMIN_NEW_BID_USER_REQ_SEARCH_KIND>[] =
  [
    {
      label: "회사명",
      value: "company",
    },
    {
      label: "담당자 이름",
      value: "name",
    },
    {
      label: "연락처",
      value: "phone",
    },
    {
      label: "이메일",
      value: "email",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<"createDate">[] = [
  {
    label: "생성일",
    value: "createDate",
  },
];

const sortTypeOptionList = ["totalBidsCount", "inProgressBidCount"];

function UserList() {
  const [userTableFilterData, setUserTableFilterData] = useAtom(
    FORWARDING_ADMIN_USER_ATOMS.USER_TABLE_FILTER_DATA
  );

  const history = useHistory();

  const resetCurrentPage = useCallback(() => {
    // 페이지가 0일 아닐때만 초기화 해준다.
    if (userTableFilterData.page !== 0) {
      setUserTableFilterData({
        ...userTableFilterData,
        page: 0,
      });
    }
  }, [setUserTableFilterData, userTableFilterData]);

  const historyTermSearchType = useMemo(() => {
    const targetSearchTypeOption = termSearchTypeOptions.find((option) => {
      return option.value === userTableFilterData.searchType;
    });

    return targetSearchTypeOption;
  }, [userTableFilterData]);

  const { debouncedSearchTerm, termSearchType, TermSearchPanel, searchTerm } =
    useSearchWithTerm({
      termSearchTypeOptions,
      historyTermSearchType,
      historySearchTerm: userTableFilterData.searchTerm,
      resetCurrentPage,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions,
      historyStartDate: userTableFilterData.startDate?.toString(),
      historyEndDate: userTableFilterData.endDate?.toString(),
      resetCurrentPage,
    });

  const { sortFilterMap, sort, order } = useTableHeadSortFilter({
    sortTypeOptionList,
  });

  const { data: userList } = ADMIN_BID_USER_QUERY.useGetAdminNewBidUserList({
    params: {
      page: userTableFilterData.page,
      perPage: userTableFilterData.perPage,
      searchTerm: debouncedSearchTerm,
      searchType: termSearchType?.value,
      ...(dateSearchType && startDate && endDate
        ? {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }
        : {}),
      sort,
      order,
      ...(userTableFilterData.forwardingManagerId
        ? { forwardingManagerId: userTableFilterData.forwardingManagerId }
        : {}),
    },
    // 검색어 입력으로 페이지가 초기화되면서 중복요청이 되는걸 방지하기 위해 추가
    enabled: debouncedSearchTerm === searchTerm,
    onSuccess: () => {
      // 요청한 값을 jotai 전역변수에 저장한다.
      setUserTableFilterData({
        ...userTableFilterData,
        searchTerm: debouncedSearchTerm,
        searchType: termSearchType?.value,
        ...(dateSearchType && startDate && endDate
          ? {
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            }
          : {}),
        sort,
        order,
      });
    },
  });

  const { getForwardingManagerName } = useGetForwardingManagerName();

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        label: "NO",
      },
      {
        id: "company",
        disablePadding: false,
        label: "회사명",
      },
      {
        id: "country",
        disablePadding: false,
        label: "국가",
        width: 80,
      },
      {
        id: "name",
        disablePadding: false,
        label: "담당자 이름",
      },
      {
        id: "phone",
        disablePadding: false,
        label: "연락처",
      },
      {
        id: "email",
        disablePadding: false,
        label: "이메일",
      },
      {
        id: "forwardingManagerId",
        disablePadding: false,
        label: "포워딩 담당자",
      },
      {
        id: "searchCount",
        disablePadding: false,
        label: "검색건수",
      },
      {
        id: "totalBidsCount",
        disablePadding: false,
        label: "견적의뢰수",
        width: 130,
        filter: sortFilterMap.get("totalBidsCount"),
      },
      {
        id: "inProgressBidCount",
        disablePadding: false,
        label: "진행건수",
        width: 130,
        filter: sortFilterMap.get("inProgressBidCount"),
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "생성일",
      },
    ];
  }, [sortFilterMap]);

  const rows = useMemo(() => {
    if (!userList?.list) return [];

    return userList?.list.map((v) => {
      const row: TableBodyRow<CellId> = {
        id: v.id,
        company: v.company,
        country: v.country,
        name: v.name,
        phone: v.phone,
        email: v.email,
        forwardingManagerId: getForwardingManagerName(v.forwardingManagerId),
        searchCount: v.searchCount,
        totalBidsCount: v.totalBidsCount,
        inProgressBidCount: v.inProgressBidCount,
        createdAt: toFormattedDate(v.createdAt),
        handleRowClick: () => {
          history.push(`/users/list/${v.id}`);
        },
      };

      return row;
    });
  }, [getForwardingManagerName, history, userList?.list]);

  return (
    <Layout title={"사용자 관리"}>
      <Box sx={{ display: "flex", gap: 1, pt: 1, px: 1, background: "#fff" }}>
        {TermSearchPanel}
        {DateSearchPanel}

        <ForwardingManagerFilter />
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <Table
          headCells={headCells}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">총 {userList?.total || 0}건</Typography>,
            ],
          }}
          pagination={{
            rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
            totalCount: userList?.total || 0,
            perPage: userTableFilterData.perPage,
            setPerPage: (perPage: number) => {
              setUserTableFilterData({
                ...userTableFilterData,
                perPage: perPage,
              });
            },
            currentPage: userTableFilterData.page,
            setCurrentPage: (currentPage: number) => {
              setUserTableFilterData({
                ...userTableFilterData,
                page: currentPage,
              });
            },
          }}
        />
      </Box>
    </Layout>
  );
}

export default withRequireAuth(UserList);
