import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { checkIfRefundTradingStatementCanBeSend } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";

import SelectRefundPartnerModal from "./SelectRefundPartnerModal";

function RefundInvoiceButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  setShowsSuccessSnackBar,
  setShowsErrorSnackBar,
  bidDetailData,
  invoiceState,
  getExchangeRateForRequest,
  requestButtonDisabledValue,
  isIssuedInvoice,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: (isTemporary: boolean) => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  setShowsSuccessSnackBar: (value: React.SetStateAction<boolean>) => void;
  setShowsErrorSnackBar: (value: React.SetStateAction<boolean>) => void;
  bidDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  getExchangeRateForRequest: (exchangeRateData: ExchangeRate[]) => number;
  requestButtonDisabledValue: boolean;
  isIssuedInvoice: boolean;
}) {
  const queryClient = useQueryClient();

  const invoiceId =
    sessionStorage.getItem("invoiceId") !== "none"
      ? Number(sessionStorage.getItem("invoiceId"))
      : undefined;

  const [showsSelectRefundPartnerModal, setShowsSelectRefundPartnerModal] =
    useState(false);

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { mutate: sendRefundInvoice } = ADMIN_FILE_QUERY.useSendRefundInvoice({
    bidId: bidDetailData.id,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
  });

  const refundPartnerCount = useMemo(() => {
    const partnerCount = bidDetailData.accountPayables.reduce((acc, cur) => {
      if (
        cur.partner?.id === 607 ||
        cur.partner?.id === 136 ||
        cur.partner?.id === 952 ||
        cur.partner?.id === 612 ||
        cur.partner?.id === 120 ||
        cur.partner?.id === 967
      ) {
        acc += 1;
      }

      return acc;
    }, 0);

    return partnerCount;
  }, [bidDetailData.accountPayables]);

  const refundPartnerBidAccountPayable = useMemo(() => {
    const refundPartner = bidDetailData.accountPayables.find((v) => {
      return (
        v.partner?.id === 607 ||
        v.partner?.id === 136 ||
        v.partner?.id === 952 ||
        v.partner?.id === 612 ||
        v.partner?.id === 120 ||
        v.partner?.id === 967
      );
    });

    return !refundPartner ? 0 : refundPartner.id;
  }, [bidDetailData.accountPayables]);

  const requestRefundInvoice = useCallback(
    (refundPartnerId: number) => {
      //invoiceId가 없으면 첫 생성 API로 있으면 수정 API로 요청
      if (invoiceId) {
        editInvoice(
          {
            invoiceData: getInvoiceDataForRequest(false),
            isTemporary: false,
            invoiceType,
          },
          {
            onSuccess: () => {
              sendRefundInvoice(
                {
                  invoiceType,
                  exchangeRate: getExchangeRateForRequest(
                    invoiceState.invoiceExchangeRateList
                  ),
                  bidAccountPayableId: refundPartnerId,
                },
                {
                  onSuccess: () => {
                    setShowsSelectRefundPartnerModal(false);
                    queryClient.invalidateQueries(
                      ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                        invoiceId,
                      })
                    );
                  },

                  onError({ response }) {
                    handleRequestError(response);
                  },
                }
              );
            },

            onError: ({ response }) => {
              handleRequestError(response);
            },
          }
        );
      } else {
        saveInvoice(
          {
            invoiceData: getInvoiceDataForRequest(false),
            isTemporary: false,
            invoiceType,
          },
          {
            onSuccess: ({ data }) => {
              sendRefundInvoice(
                {
                  bidAccountPayableId: refundPartnerId,
                  invoiceType,
                  exchangeRate: getExchangeRateForRequest(
                    invoiceState.invoiceExchangeRateList
                  ),
                },
                {
                  onSuccess: () => {
                    setShowsSelectRefundPartnerModal(false);
                    sessionStorage.setItem("invoiceId", data.id.toString());
                  },

                  onError({ response }) {
                    handleRequestError(response);
                  },
                }
              );
            },

            onError: ({ response }) => {
              handleRequestError(response);
            },
          }
        );
      }
    },
    [
      editInvoice,
      getExchangeRateForRequest,
      getInvoiceDataForRequest,
      handleRequestError,
      invoiceId,
      invoiceState.invoiceExchangeRateList,
      invoiceType,
      queryClient,
      saveInvoice,
      sendRefundInvoice,
    ]
  );

  const handleRefundInvoiceRequestClick = useCallback(() => {
    if (refundPartnerCount > 1) {
      setShowsSelectRefundPartnerModal(true);
      return;
    }

    if (!refundPartnerBidAccountPayable) {
      setShowsErrorSnackBar(true);
      return;
    }

    requestRefundInvoice(refundPartnerBidAccountPayable);
  }, [
    refundPartnerBidAccountPayable,
    refundPartnerCount,
    requestRefundInvoice,
    setShowsErrorSnackBar,
  ]);

  return (
    <>
      <Button
        disabled={
          saveInvoiceLoading ||
          editInvoiceLoading ||
          checkIfRefundTradingStatementCanBeSend(bidDetailData.projectStatus) ||
          requestButtonDisabledValue ||
          isIssuedInvoice
        }
        variant="contained"
        color="secondary"
        onClick={handleRefundInvoiceRequestClick}
      >
        {saveInvoiceLoading || editInvoiceLoading ? (
          <CircularProgress />
        ) : (
          "부킹 커미션 발송"
        )}
      </Button>

      {showsSelectRefundPartnerModal && (
        <SelectRefundPartnerModal
          setShowsSelectRefundPartnerModal={setShowsSelectRefundPartnerModal}
          showsSelectRefundPartnerModal={showsSelectRefundPartnerModal}
          bidDetailData={bidDetailData}
          requestRefundInvoice={requestRefundInvoice}
        />
      )}
    </>
  );
}

export default RefundInvoiceButton;
