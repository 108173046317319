import { useMemo, useState } from "react";

import ADMIN_BID_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import { AdminUserGuideList } from "@sellernote/_shared/src/types/forwarding/adminBidUser";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import {
  getUserGuideFieldCategory,
  getUserGuideName,
} from "@sellernote/_shared/src/utils/forwarding/adminUser";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import UserComment from "./UserComment";

type CellId = keyof AdminUserGuideList | "domain";

const GuideUser = () => {
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: userGuideList } = ADMIN_BID_USER_QUERY.useGetUserGuideList({
    isGuide: true,
  });

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "id",
        disablePadding: false,
        width: 80,
        label: "번호",
      },
      {
        id: "company",
        disablePadding: false,
        label: "회사명",
      },
      {
        id: "name",
        disablePadding: false,
        label: "담당자 이름",
      },
      {
        id: "phone",
        disablePadding: false,
        label: "연락처",
      },
      {
        id: "email",
        disablePadding: false,
        label: "이메일",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "신청일자",
        width: 230,
      },
      {
        id: "domain",
        disablePadding: false,
        label: "사업부문",
      },
      {
        id: "careType",
        disablePadding: false,
        label: "종류",
      },
      {
        id: "isGuideDownloaded",
        disablePadding: false,
        label: "다운로드",
      },
      {
        id: "userComment",
        disablePadding: false,
        label: "문의사항",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!userGuideList?.list) return [];

    return (
      userGuideList?.list
        .map((userGuideItem) => {
          const row: TableBodyRow<CellId> = {
            id: userGuideItem.id,
            company: userGuideItem.company,
            name: userGuideItem.name,
            phone: userGuideItem.phone,
            email: userGuideItem.email,
            createdAt: toFormattedDate(
              userGuideItem.createdAt,
              "YYYY.MM.DD a hh:mm:ss"
            ),
            domain: getUserGuideFieldCategory(userGuideItem.careType),
            careType: getUserGuideName(userGuideItem.careType),
            isGuideDownloaded: changeBooleanValueToKr(
              userGuideItem.isGuideDownloaded
            ),
            userComment: userGuideItem.userComment ? (
              <UserComment comment={userGuideItem.userComment} />
            ) : (
              "-"
            ),
          };

          return row;
        })
        .reverse()
        // 현재 가이드 리스트 API에서 페이지 필터가 안되는 상태라서 프론트에서 임시로 추가함
        .filter((_, i) => {
          const startIndex = currentPage * perPage;
          const endIndex = startIndex + perPage;
          return i >= startIndex && i < endIndex;
        })
    );
  }, [currentPage, perPage, userGuideList?.list]);

  return (
    <Layout breadcrumbs={["사용자 관리"]} title={"가이드 신청 리스트"}>
      <Table
        title="가이드 신청 리스트"
        headCells={headCells}
        rows={rows}
        pagination={{
          totalCount: userGuideList?.total || 0,
          perPage,
          setPerPage,
          currentPage,
          setCurrentPage,
        }}
      />
    </Layout>
  );
};

export default withRequireAuth(GuideUser);
