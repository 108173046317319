import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  InvoiceData,
  TradingStatementElem,
} from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import {
  calculateRTonForTradingStatement,
  checkIsIssuedInvoices,
  getAllTotalItemPriceOfFeeData,
  getAllTotalVatPriceOfFeeData,
  getTotalTaxExemptPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import CustomsInvoiceButton from "./CustomsInvoiceButton";
import DeleteInvoiceModal from "./DeleteInvoiceModal";
import RefundInvoiceButton from "./RefundInvoiceButton";
import SaveAndDownloadButton from "./SaveAndDownloadButton";
import TemporarySaveButton from "./TemporarySaveButton";
import UserInvoiceButton from "./UserInvoiceButton";

//TODO: 쿼리가 많고 컴포넌트 양이 많아 기능 추가시 분할 결정
const RequestInvoice = ({
  bidDetailData,
  requestButtonDisabledValue,
  invoiceState,
  invoiceData,
  directAirRton,
}: {
  bidDetailData: TrelloBidDetail;
  requestButtonDisabledValue: boolean;
  invoiceState: ApplyBidFormData;
  invoiceData: InvoiceData | undefined;
  directAirRton: number | undefined;
}) => {
  const invoiceId =
    sessionStorage.getItem("invoiceId") !== "none"
      ? Number(sessionStorage.getItem("invoiceId"))
      : undefined;

  const { bidId, invoiceType } = useParams<{
    bidId: string;
    invoiceType: InvoiceType;
  }>();

  const [showsDeleteInvoiceModal, setShowsDeleteInvoiceModal] = useState(false);

  const {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
    setSuccessMessage,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  const getTotalPrice = useCallback(
    () =>
      getAllTotalItemPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ),
    [
      invoiceState.freightFeeData,
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData,
    ]
  );

  const getVatPrice = useCallback(
    () =>
      getAllTotalVatPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ),
    [
      invoiceState.freightFeeData,
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData,
    ]
  );

  const getTaxExemptPrice = useCallback(() => {
    const allFeeData = invoiceState.freightFeeData.concat(
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData
    );

    return getTotalTaxExemptPriceOfFeeData(allFeeData);
  }, [
    invoiceState.freightFeeData,
    invoiceState.domesticFeeData,
    invoiceState.localFeeData,
    invoiceState.inlandFeeData,
    invoiceState.otherFeeData,
    invoiceState.taxFeeData,
  ]);

  const getFinalPrice = useCallback(() => {
    return (
      getAllTotalItemPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ) +
      getAllTotalVatPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ) +
      getTaxExemptPrice()
    );
  }, [
    invoiceState.freightFeeData,
    invoiceState.domesticFeeData,
    invoiceState.localFeeData,
    invoiceState.inlandFeeData,
    invoiceState.otherFeeData,
    invoiceState.taxFeeData,
    getTaxExemptPrice,
  ]);
  const BidDetailInvoiceData = useMemo(() => {
    if (invoiceType === "refund") {
      return bidDetailData.refundInvoice;
    }
    if (invoiceType === "warehouseReceipt" && bidDetailData.warehouseInvoice) {
      const warehouseInvoiceData = bidDetailData.warehouseInvoice.find((v) => {
        return v.id === invoiceId;
      });

      return warehouseInvoiceData;
    }
    if (invoiceType === "etcDeposit" && bidDetailData.etcDepositInvoice) {
      const etcDepositInvoiceData = bidDetailData.etcDepositInvoice.find(
        (v) => {
          return v.id === invoiceId;
        }
      );
      return etcDepositInvoiceData;
    }

    return bidDetailData.bidInvoice;
  }, [
    bidDetailData.bidInvoice,
    bidDetailData.etcDepositInvoice,
    bidDetailData.refundInvoice,
    bidDetailData.warehouseInvoice,
    invoiceId,
    invoiceType,
  ]);

  const isIssuedInvoice = useMemo(() => {
    return checkIsIssuedInvoices(BidDetailInvoiceData);
  }, [BidDetailInvoiceData]);

  const isIssuedBidInvoice = useMemo(() => {
    return checkIsIssuedInvoices(bidDetailData.bidInvoice);
  }, [bidDetailData.bidInvoice]);

  const returnSaveInvoiceErrorMessage = useCallback(
    (errorCode: string) => {
      if (errorCode === "E356") {
        setErrorMessage("지정된 부킹 커미션 파트너사가 아닙니다.");
        setShowsErrorSnackBar(true);
        return;
      }

      if (errorCode === "E071") {
        setErrorMessage("이미 발행 요청된 거래명세서는 갱신할 수 없습니다.");
        setShowsErrorSnackBar(true);
        return;
      }

      setErrorMessage("거래명세서 생성에 실패했습니다.");
      setShowsErrorSnackBar(true);
    },
    [setShowsErrorSnackBar, setErrorMessage]
  );

  const getInvoiceFeeDataForRequest = useCallback(
    (feeData: TradingStatementElem[]) => {
      if (!feeData) {
        return [];
      }

      return feeData.map((v) => {
        return {
          ...v,
          amount: (v.amount ?? "").toString(),
          itemPrice: toThousandUnitFormat(v.itemPrice),
          unitPrice: toThousandUnitFormat(v.unitPrice),
        };
      });
    },
    []
  );

  const changeExchangeRateDateToString = useCallback(
    (exchangeRateData: ExchangeRate[]) => {
      return exchangeRateData
        .filter((v) => {
          return v.currency !== "KRW";
        })
        .map((v) => {
          return v.currency === "CNH"
            ? ` CNY: ${v.rate}`
            : ` ${v.currency}: ${v.rate}`;
        })
        .toString();
    },
    []
  );

  const getExchangeCurrency = useCallback(
    (exchangeRateData: ExchangeRate[]) => {
      return exchangeRateData
        .filter((v) => {
          return v.currency !== "KRW";
        })
        .map((v) => {
          return v.currency === "CNH" ? "CNY" : `${v.currency}`;
        })
        .toString();
    },
    []
  );

  const getExchangeRateForRequest = useCallback(
    (exchangeRateData: ExchangeRate[]) => {
      const exchangeRate = exchangeRateData.find((v) => {
        return v.currency === getExchangeCurrency(exchangeRateData);
      });
      return exchangeRate?.rate || 0;
    },
    [getExchangeCurrency]
  );

  const getInvoiceDataForRequest = useCallback(
    (isTemporary: boolean) => {
      const calculateRton = calculateRTonForTradingStatement({
        shipmentDetailData: bidDetailData,
        cbm: invoiceState.cbm,
        ton: invoiceState.ton,
        directAirRton,
      });

      const requestData: RequestInvoiceData = {
        id: invoiceId,
        exchangeRate:
          invoiceData && isTemporary
            ? invoiceData.sendDateExchangeRate
            : getExchangeRateForRequest(invoiceState.invoiceExchangeRateList),
        tempExchangeRate: isTemporary
          ? getExchangeRateForRequest(invoiceState.invoiceExchangeRateList)
          : undefined,
        BRN: invoiceState.userBRN,
        bidId: bidDetailData.id,
        createdAt: toFormattedDate(new Date(), "YYYY-MM-DD"),
        recipient: invoiceState.recipient,
        blNumber: invoiceState.blNumber,
        voyageNumber:
          bidDetailData.freightType === "AIR"
            ? invoiceState.shipName
            : `${invoiceState.shipName} / ${invoiceState.voyageNo}`,
        package: invoiceState.packageValue,
        cbm:
          bidDetailData.freightType === "AIR"
            ? `${toThousandUnitFormat(invoiceState.ton)} kgs`
            : `${invoiceState.cbm} CBM / ${toThousandUnitFormat(
                invoiceState.ton
              )} kgs`,
        rton:
          calculateRton === 1
            ? bidDetailData.locale === "SG"
              ? "1(minimum value)"
              : "1(최소값 적용)"
            : calculateRton.toString(),
        arrivalDate: invoiceState.arrivalDate,
        portOfLoading: `${invoiceState.startPort.toUpperCase()} / ${invoiceState.startCountryName.toUpperCase()}`,
        portOfDischarge: `${invoiceState.endPort.toUpperCase()} / ${invoiceState.endCountryName.toUpperCase()}`,
        incoterms: bidDetailData.incoterms,
        mode: bidDetailData.freightType,
        freightType: bidDetailData.freightType,
        currency: changeExchangeRateDateToString(
          invoiceState.invoiceExchangeRateList
        ),
        totalPrice: toThousandUnitFormat(getTotalPrice()),
        vatPrice: toThousandUnitFormat(getVatPrice()),
        finalPrice: toThousandUnitFormat(getFinalPrice()),
        taxExemptPrice: toThousandUnitFormat(getTaxExemptPrice()),
        comment: invoiceState.comment.replace(/(?:\r\n|\r|\n)/g, "<br />"),
        quotationData: {
          freightFee: getInvoiceFeeDataForRequest(invoiceState.freightFeeData),
          localFee: getInvoiceFeeDataForRequest(invoiceState.localFeeData),
          domesticFee: getInvoiceFeeDataForRequest(
            invoiceState.domesticFeeData
          ),
          inlandFee: getInvoiceFeeDataForRequest(invoiceState.inlandFeeData),
          otherFee: getInvoiceFeeDataForRequest(invoiceState.otherFeeData),
          taxFee: getInvoiceFeeDataForRequest(invoiceState.taxFeeData),
        },
        containersNumberInfo:
          bidDetailData.locale === "KR" && bidDetailData.isImport
            ? undefined
            : invoiceState.containersNumberInfo.map((v) => {
                return v.containersNumber;
              }),
      };

      return requestData;
    },
    [
      bidDetailData,
      invoiceState.cbm,
      invoiceState.ton,
      invoiceState.invoiceExchangeRateList,
      invoiceState.userBRN,
      invoiceState.recipient,
      invoiceState.blNumber,
      invoiceState.shipName,
      invoiceState.voyageNo,
      invoiceState.packageValue,
      invoiceState.arrivalDate,
      invoiceState.startPort,
      invoiceState.startCountryName,
      invoiceState.endPort,
      invoiceState.endCountryName,
      invoiceState.comment,
      invoiceState.freightFeeData,
      invoiceState.localFeeData,
      invoiceState.domesticFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData,
      invoiceState.containersNumberInfo,
      directAirRton,
      invoiceId,
      invoiceData,
      getExchangeRateForRequest,
      changeExchangeRateDateToString,
      getTotalPrice,
      getVatPrice,
      getFinalPrice,
      getTaxExemptPrice,
      getInvoiceFeeDataForRequest,
    ]
  );

  const handleRequestError = useCallback(
    (response: AxiosResponse<ResponseFailureInfo, any> | undefined) => {
      const failureInfo = response?.data as unknown as ResponseFailureInfo;
      returnSaveInvoiceErrorMessage(failureInfo.errorCode);
    },
    [returnSaveInvoiceErrorMessage]
  );

  const handleDeleteInvoiceModalOpen = useCallback(() => {
    setShowsDeleteInvoiceModal(true);
  }, []);

  const handleDeleteInvoiceModalClose = useCallback(() => {
    setShowsDeleteInvoiceModal(false);
  }, []);

  const hasDeleteButton = useMemo(() => {
    return invoiceType === "warehouseReceipt" || invoiceType === "etcDeposit";
  }, [invoiceType]);

  return (
    <Grid container direction="column">
      <Grid container justifyContent="flex-end">
        <Grid
          item
          container
          xs={hasDeleteButton ? 10 : 11}
          spacing={1}
          justifyContent="flex-start"
        >
          <Grid item>
            <TemporarySaveButton
              invoiceType={invoiceType}
              getInvoiceDataForRequest={getInvoiceDataForRequest}
              handlerErrorRequest={handleRequestError}
              setSuccessMessage={setSuccessMessage}
              setShowsSuccessSnackBar={setShowsSuccessSnackBar}
              bidDetailData={bidDetailData}
            />
          </Grid>

          <Grid item>
            <SaveAndDownloadButton
              invoiceType={invoiceType}
              getInvoiceDataForRequest={getInvoiceDataForRequest}
              handleRequestError={handleRequestError}
              setSuccessMessage={setSuccessMessage}
              setShowsSuccessSnackBar={setShowsSuccessSnackBar}
              setErrorMessage={setErrorMessage}
              setShowsErrorSnackBar={setShowsErrorSnackBar}
              invoiceState={invoiceState}
              region={bidDetailData.locale}
              bidDetailData={bidDetailData}
            />
          </Grid>

          {invoiceType !== "refund" && bidDetailData.isImport && (
            <Grid item>
              <CustomsInvoiceButton
                invoiceType={invoiceType}
                getInvoiceDataForRequest={getInvoiceDataForRequest}
                handleRequestError={handleRequestError}
                setSuccessMessage={setSuccessMessage}
                setShowsSuccessSnackBar={setShowsSuccessSnackBar}
                setErrorMessage={setErrorMessage}
                setShowsErrorSnackBar={setShowsErrorSnackBar}
                bidDetailData={bidDetailData}
                invoiceState={invoiceState}
                isIssuedInvoice={isIssuedInvoice}
                isIssuedBidInvoice={isIssuedBidInvoice}
                requestButtonDisabledValue={requestButtonDisabledValue}
                getExchangeRateForRequest={getExchangeRateForRequest}
              />
            </Grid>
          )}

          {invoiceType !== "refund" && (
            <Grid item>
              <UserInvoiceButton
                invoiceType={invoiceType}
                getInvoiceDataForRequest={getInvoiceDataForRequest}
                handleRequestError={handleRequestError}
                setShowsSuccessSnackBar={setShowsSuccessSnackBar}
                setShowsErrorSnackBar={setShowsErrorSnackBar}
                bidDetailData={bidDetailData}
                invoiceState={invoiceState}
                isIssuedInvoice={isIssuedInvoice}
                requestButtonDisabledValue={requestButtonDisabledValue}
                getExchangeRateForRequest={getExchangeRateForRequest}
                setErrorMessage={setErrorMessage}
              />
            </Grid>
          )}
          <Grid item>
            {invoiceType === "refund" && (
              <RefundInvoiceButton
                invoiceType={invoiceType}
                getInvoiceDataForRequest={getInvoiceDataForRequest}
                handleRequestError={handleRequestError}
                setShowsSuccessSnackBar={setShowsSuccessSnackBar}
                setShowsErrorSnackBar={setShowsErrorSnackBar}
                bidDetailData={bidDetailData}
                invoiceState={invoiceState}
                isIssuedInvoice={isIssuedInvoice}
                requestButtonDisabledValue={requestButtonDisabledValue}
                getExchangeRateForRequest={getExchangeRateForRequest}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={1}>
          <Button
            variant="outlined"
            onClick={() => history.go(-1)}
            className="back-button"
          >
            뒤로가기
          </Button>
        </Grid>

        {hasDeleteButton && (
          <Grid item xs={1}>
            <Button
              disabled={!invoiceId || invoiceData?.isSended}
              onClick={handleDeleteInvoiceModalOpen}
              variant="contained"
              color="error"
            >
              삭제
            </Button>
          </Grid>
        )}
      </Grid>

      {invoiceData?.isTemporary && (
        <Grid item>
          <Typography sx={{ color: red[600] }} variant="body2" component="div">
            거래명세서가 임시저장된 상태입니다.
          </Typography>
        </Grid>
      )}

      {UploadResponseSnackBar}

      {showsDeleteInvoiceModal && invoiceId && (
        <DeleteInvoiceModal
          handleDeleteInvoiceModalClose={handleDeleteInvoiceModalClose}
          showsDeleteInvoiceModal={showsDeleteInvoiceModal}
          invoiceId={invoiceId}
          setShowsSuccessSnackBar={setShowsSuccessSnackBar}
          setShowsErrorSnackBar={setShowsErrorSnackBar}
        />
      )}
    </Grid>
  );
};

export default RequestInvoice;
