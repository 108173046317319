import React, { useMemo, useState } from "react";
import { Card, Col, Input, Row, Select, Table } from "antd";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import { Zone } from "@sellernote/_shared/src/types/common/common";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

const { Option } = Select;
const InputGroup = Input.Group;

const ZoneList = () => {
  const [searchText, setSearchText] = useState("");
  const [searchType] = useState("name");

  const { data: zoneList } = ADMIN_COMMON_QUERY.useGetZoneList();

  const zoneListFilterData = useMemo(() => {
    if (!zoneList) {
      return [];
    }
    const filterData = zoneList.filter((data: Zone) => {
      switch (searchType) {
        case "name":
          return data.name.includes(searchText);
        default:
          return data.name.includes("");
      }
    });
    return filterData;
  }, [searchText, searchType, zoneList]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const columns = [
    {
      title: "번호",
      dataIndex: "id",
      width: "80",
    },
    {
      title: "장소",
      dataIndex: "name",
    },
  ];

  return (
    <Layout breadcrumbs={["장소 관리"]} title={"장소 관리"}>
      <Card>
        <Row justify="end" align="middle" style={{ marginBottom: "10px" }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "50%" }}
          >
            <Col span={24}>
              <InputGroup compact>
                <Select defaultValue="name" style={{ width: "100px" }}>
                  <Option value="name">{"장소"}</Option>
                </Select>
                <Input
                  style={{ width: "calc(100% - 110px)", marginLeft: "10px" }}
                  onChange={handleSearch}
                  allowClear
                />
              </InputGroup>
            </Col>
          </Row>
        </Row>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "10px" }}
        >
          <span>총 {zoneListFilterData.length}개</span>
        </Row>

        <Table
          rowKey={(record: Zone) => record.id}
          dataSource={zoneListFilterData}
          columns={columns}
          pagination={{
            total: zoneListFilterData.length,
            pageSize: 25,
            showSizeChanger: false,
          }}
        />
      </Card>
    </Layout>
  );
};

export default withRequireAuth(ZoneList);
