import styled from "styled-components";

const container = styled.div`
  width: 500px;
  max-height: 85px;
  overflow: auto;
  padding: 0 16px;
  cursor: pointer;

  > .pop-list {
    width: 500px;
  }
`;

const popList = styled.div`
  width: 500px;
`;

export default {
  container,
  popList,
};
