import { useEffect, useMemo } from "react";
import {
  Checkbox,
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { GET_ADMIN_SETTLEMENT_DEPOSIT_COMPANIES_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminSettlement";
import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import { FORWARDING_ADMIN_SETTLEMENT_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminSettlement";
import { DepositCompanyData } from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getInvoiceTypeKR } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";

import MatchBidIdButton from "./MatchBidIdButton";

type CellId = keyof DepositCompanyData | "checkbox";

function CompanyTable({
  settlementDepositCompanies,
  depositAmount,
  paymentInvoiceId,
  handleModalClose,
  refetchSettlementDepositCompanies,
}: {
  settlementDepositCompanies:
    | GET_ADMIN_SETTLEMENT_DEPOSIT_COMPANIES_RES
    | undefined;
  depositAmount: number;
  paymentInvoiceId: number;
  handleModalClose: () => void;
  refetchSettlementDepositCompanies: () => void;
}) {
  const setRequestAmount = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_REQUEST_AMOUNT
  );

  const setInvoiceIds = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_INVOICE_ID
  );

  const requestAmount = useRecoilValue(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_REQUEST_AMOUNT
  );

  const invoiceIds = useRecoilValue(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_INVOICE_ID
  );

  const {
    array: checkBoxArr,
    set: checkBoxSet,
    toggle: toggleCheckBox,
    init: initCheckBoxSet,
  } = useSet<number>();

  //isMapped가 true인 항목들은 체크가 되어야 함
  useEffect(() => {
    if (settlementDepositCompanies) {
      initCheckBoxSet(invoiceIds);
    }
  }, [settlementDepositCompanies, initCheckBoxSet, invoiceIds]);

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "checkbox",
        disablePadding: false,
        label: "선택",
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
      },
      {
        id: "invoiceType",
        disablePadding: false,
        label: "구분",
      },
      {
        id: "sendDate",
        disablePadding: false,
        label: "발송일",
      },
      {
        id: "finalPrice",
        disablePadding: false,
        label: "청구금액",
        numeric: true,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!settlementDepositCompanies) return [];

    return settlementDepositCompanies.map((v) => {
      const row: TableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(v.invoiceId)}
            onClick={(e) => {
              e.stopPropagation();

              if (checkBoxSet.has(v.invoiceId)) {
                setRequestAmount(requestAmount - v.finalPrice);
                setInvoiceIds(
                  invoiceIds.filter((invoiceId) => {
                    return v.invoiceId !== invoiceId;
                  })
                );
              } else {
                setRequestAmount(requestAmount + v.finalPrice);
                setInvoiceIds([...invoiceIds, v.invoiceId]);
              }
              toggleCheckBox(v.invoiceId);
            }}
          />
        ),
        bidId: v.bidId,
        invoiceType: getInvoiceTypeKR(v.invoiceType),
        sendDate: toFormattedDate(v.sendDate, "YYYY-MM-DD"),
        finalPrice: `${toThousandUnitFormat(v.finalPrice)}`,
      };
      return row;
    });
  }, [
    checkBoxSet,
    invoiceIds,
    requestAmount,
    setInvoiceIds,
    setRequestAmount,
    settlementDepositCompanies,
    toggleCheckBox,
  ]);

  const differenceAmount = requestAmount - depositAmount;

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Table sx={{ height: 300 }} headCells={headCells} rows={rows} />
      </Grid>

      <Grid container item justifyContent={"flex-end"}>
        <Grid item>
          <TableContainer component={Paper}>
            <MuiTable sx={{ minWidth: 400 }}>
              <TableBody>
                <TableRow>
                  <TableCell>청구금액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    requestAmount
                  )}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>입금액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    depositAmount
                  )}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>차액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    differenceAmount
                  )}`}</TableCell>
                </TableRow>
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container item justifyContent={"center"}>
        <MatchBidIdButton
          checkBoxArr={checkBoxArr}
          paymentInvoiceId={paymentInvoiceId}
          handleModalClose={handleModalClose}
          refetchSettlementDepositCompanies={refetchSettlementDepositCompanies}
        />
      </Grid>
    </Grid>
  );
}

export default CompanyTable;
