import Snackbar from "@mui/material/Snackbar";

import { getEmailValidation } from "@sellernote/_shared/src/utils/common/validation";

export default function InvalidAlert({
  showsInvalidFormAlert,
  setShowsInvalidFormAlert,
  countryInChargeList,
  serviceRegion,
  language,
  name,
  transportModeList,
  businessArea,
  managerName,
  email,
}: {
  showsInvalidFormAlert: boolean;
  setShowsInvalidFormAlert: (val: boolean) => void;
  countryInChargeList: string[];
  serviceRegion: string;
  language: string;
  name: string;
  transportModeList: string[];
  businessArea: string;
  managerName: string;
  email: string;
}) {
  const warningMessage = (() => {
    if (!countryInChargeList.length) return "담당 국가를 입력해주세요.";
    if (!serviceRegion) return "서비스 지역을 입력해주세요.";
    if (!language) return "언어를 입력해주세요.";
    if (!name) return "회사명을 입력해주세요.";
    if (!transportModeList.length) return "운송타입을 입력해주세요.";
    if (!businessArea) return "파트너 유형을 입력해주세요.";
    if (!managerName || !email) return "담당자 또는 이메일을 확인해주세요.";

    if (!getEmailValidation(email).result) return "이메일 형식을 확인해주세요.";

    return "";
  })();

  return (
    <Snackbar
      open={showsInvalidFormAlert}
      onClose={() => setShowsInvalidFormAlert(false)}
      message={warningMessage}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    />
  );
}
