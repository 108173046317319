import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminSettlement";
import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import { FORWARDING_ADMIN_SETTLEMENT_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminSettlement";
import { WithdrawalPartnerData } from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import WithdrawalRequestModal from "pages/settlement/purchaseManagement/WithdrawalRequestModal";

type CellId = keyof WithdrawalPartnerData | "checkbox";

function PartnerCompanyTable({
  settlementWithdrawalPartners,
  withdrawalAmount,
  paymentInvoiceId,
  handleModalClose,
  refetchSettlementWithdrawalPartners,
}: {
  settlementWithdrawalPartners:
    | GET_ADMIN_SETTLEMENT_WITHDRAWAL_PARTNERS_RES
    | undefined;
  withdrawalAmount: number;
  paymentInvoiceId: number;
  handleModalClose: () => void;
  refetchSettlementWithdrawalPartners: () => void;
}) {
  const setRequestAmount = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_REQUEST_AMOUNT
  );

  const setInvoiceIds = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_INVOICE_ID
  );

  const requestAmount = useRecoilValue(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_REQUEST_AMOUNT
  );

  const invoiceIds = useRecoilValue(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.WITHDRAWAL_INVOICE_ID
  );

  const [showsWithdrawalRequestModal, setShowsWithdrawalRequestModal] =
    useState(false);
  const [bidAccountPayableId, setBidAccountPayableId] = useState(0);

  const { mutate: matchBidIdToWithdrawal, isLoading: isMatchingOngoing } =
    ADMIN_SETTLEMENT_QUERY.useMatchBidIdToWithdrawal();

  const {
    array: checkBoxArr,
    set: checkBoxSet,
    toggle: toggleCheckBox,
    init: initCheckBoxSet,
  } = useSet<number>();

  const {
    UploadResponseSnackBar,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  useEffect(() => {
    if (settlementWithdrawalPartners) {
      initCheckBoxSet(invoiceIds);
    }
  }, [settlementWithdrawalPartners, initCheckBoxSet, invoiceIds]);

  const handleBidIdMatch = useCallback(() => {
    if (isMatchingOngoing) return;

    matchBidIdToWithdrawal(
      {
        paymentInvoiceId,
        withdrawalRequestIds: checkBoxArr,
      },
      {
        onSuccess: () => {
          setShowsSuccessSnackBar(true);
          refetchSettlementWithdrawalPartners();
        },

        onError: ({ response }) => {
          if (response?.data?.errorCode === "E182") {
            setErrorMessage("이미 출금완료된 출금요청을 매핑할 수 없습니다.");
          }

          setShowsErrorSnackBar(true);
        },
      }
    );
  }, [
    checkBoxArr,
    matchBidIdToWithdrawal,
    paymentInvoiceId,
    refetchSettlementWithdrawalPartners,
    setErrorMessage,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
    isMatchingOngoing,
  ]);

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "checkbox",
        disablePadding: false,
        label: "선택",
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
      },
      {
        id: "forwardingManager",
        disablePadding: false,
        label: "운영담당자",
      },
      {
        id: "requestDate",
        disablePadding: false,
        label: "출금요청일",
      },
      {
        id: "requestAmount",
        disablePadding: false,
        label: "출금요청금액",
        numeric: true,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!settlementWithdrawalPartners) return [];

    return settlementWithdrawalPartners.map((v) => {
      const row: TableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(v.withdrawalRequestId)}
            onClick={(e) => {
              e.stopPropagation();

              if (checkBoxSet.has(v.withdrawalRequestId)) {
                setRequestAmount(requestAmount - v.requestAmount);
                setInvoiceIds(
                  invoiceIds.filter((invoiceId) => {
                    return v.withdrawalRequestId !== invoiceId;
                  })
                );
              } else {
                setRequestAmount(requestAmount + v.requestAmount);
                setInvoiceIds([...invoiceIds, v.withdrawalRequestId]);
              }
              toggleCheckBox(v.withdrawalRequestId);
            }}
          />
        ),
        bidId: v.bidId,
        forwardingManager: v.forwardingManager,
        requestDate: toFormattedDate(v.requestDate, "YYYY-MM-DD"),
        requestAmount: (
          <Button
            variant="text"
            onClick={() => {
              setBidAccountPayableId(v.bidAccountPayableId);
              setShowsWithdrawalRequestModal(true);
            }}
          >
            {toThousandUnitFormat(v.requestAmount)}
          </Button>
        ),
      };
      return row;
    });
  }, [
    checkBoxSet,
    invoiceIds,
    requestAmount,
    setInvoiceIds,
    setRequestAmount,
    settlementWithdrawalPartners,
    toggleCheckBox,
  ]);

  const differenceAmount = requestAmount - withdrawalAmount;

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Table sx={{ height: 300 }} headCells={headCells} rows={rows} />
      </Grid>

      <Grid container item justifyContent={"flex-end"}>
        <Grid item>
          <TableContainer component={Paper}>
            <MuiTable sx={{ minWidth: 500 }}>
              <TableBody>
                <TableRow>
                  <TableCell>출금요청금액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    requestAmount
                  )}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>출금액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    withdrawalAmount
                  )}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>차액</TableCell>

                  <TableCell align="right">{`${toThousandUnitFormat(
                    differenceAmount
                  )}`}</TableCell>
                </TableRow>
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container item justifyContent={"center"}>
        <Button
          variant="contained"
          onClick={handleBidIdMatch}
          disabled={isMatchingOngoing}
        >
          등록
        </Button>
      </Grid>

      {UploadResponseSnackBar}

      {showsWithdrawalRequestModal && (
        <WithdrawalRequestModal
          setShowsWithdrawalRequestModal={setShowsWithdrawalRequestModal}
          showsWithdrawalRequestModal={showsWithdrawalRequestModal}
          bidAccountPayableId={bidAccountPayableId}
        />
      )}
    </Grid>
  );
}

export default PartnerCompanyTable;
