import { useCallback, useMemo, useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { Button, Grid, Popover, Typography } from "@mui/material";

import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { InvoiceData } from "@sellernote/_shared/src/types/forwarding/tradingStatement";

const ExchangeDesc = ({
  invoiceData,
  invoiceState,
  setValue,
}: {
  invoiceState: ApplyBidFormData;
  invoiceData: InvoiceData | undefined;
  setValue: UseFormSetValue<ApplyBidFormData>;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const Content = useMemo(() => {
    return (
      <Grid container>
        {invoiceState.todayExchangeRateList.map((v) => {
          return (
            <Grid item container key={v.currency}>
              <Grid item>
                <Typography>{v.currency} : </Typography>
              </Grid>

              <Grid item>
                <Typography>{v.rate}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }, [invoiceState.todayExchangeRateList]);

  const updateTodayExchangeRate = useCallback(() => {
    setValue("invoiceExchangeRateList", invoiceState.todayExchangeRateList);
  }, [invoiceState.todayExchangeRateList, setValue]);

  return (
    <Grid container justifyContent={"flex-end"} sx={{ width: "90%" }}>
      <Grid item>
        <Button
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          onClick={updateTodayExchangeRate}
          variant="contained"
        >
          EX-RATE
        </Button>

        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {Content}
        </Popover>
      </Grid>

      <Grid item container justifyContent={"flex-end"}>
        <Grid item>
          <Typography>고객사 발송: </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {invoiceData?.isSended && invoiceData?.sendDate}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justifyContent={"flex-end"}>
        <Grid item>
          <Typography>청구환율: </Typography>
        </Grid>

        <Grid item>
          <Typography>
            {invoiceData?.isSended && invoiceData?.sendDateExchangeRate}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExchangeDesc;
