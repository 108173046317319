import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Autocomplete, Button } from "@mui/material";

import { Port } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { TradingStatementElem } from "@sellernote/_shared/src/types/forwarding/tradingStatement";
import {
  ExchangeRate,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import {
  multiplyByBigNumber,
  toThousandUnitFormat,
} from "@sellernote/_shared/src/utils/common/number";
import { getValueAsNumberOnly } from "@sellernote/_shared/src/utils/common/validation";
import {
  calculateRTonForTradingStatement,
  getAllTotalItemPriceOfFeeData,
  getAllTotalVatPriceOfFeeData,
  getTotalTaxExemptPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";
import AdminDetailDescription from "@sellernote/_shared-for-forwarding-admin/src/components/AdminDetailDescription";
import DatePicker from "@sellernote/_shared-for-forwarding-admin/src/components/DatePicker";

import ContainerList from "./ContainerList";
import ContainersNumberTextField from "./ContainerNumberTextField";

const TradingStateDescriptions = ({
  invoiceState,
  setValue,
  portData,
  setRequestButtonDisabledValue,
  bidDetailData,
  control,

  directAirRton,
  handleDirectAirRtonChange,
}: {
  invoiceState: ApplyBidFormData;
  setValue: UseFormSetValue<ApplyBidFormData>;
  portData: Port[];
  setRequestButtonDisabledValue: Dispatch<SetStateAction<boolean>>;
  bidDetailData: TrelloBidDetail;
  control: Control<ApplyBidFormData>;

  /** AIR일 때 직접 입력하는 Rton 값 */
  directAirRton: number | undefined;
  handleDirectAirRtonChange: (rton: number | undefined) => void;
}) => {
  const portDataForAutoCompleteOptions = useMemo(() => {
    return portData.map((v) => {
      return { value: v.nameEN, label: v.nameEN };
    });
  }, [portData]);

  const getTotalPrice = useCallback(
    () =>
      getAllTotalItemPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ),
    [
      invoiceState.freightFeeData,
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData,
    ]
  );

  const getVatPrice = useCallback(
    () =>
      getAllTotalVatPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ),
    [
      invoiceState.freightFeeData,
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData,
    ]
  );

  const getTaxExemptPrice = useCallback(() => {
    const allFeeData = invoiceState.freightFeeData.concat(
      invoiceState.domesticFeeData,
      invoiceState.localFeeData,
      invoiceState.inlandFeeData,
      invoiceState.otherFeeData,
      invoiceState.taxFeeData
    );

    return getTotalTaxExemptPriceOfFeeData(allFeeData);
  }, [
    invoiceState.freightFeeData,
    invoiceState.domesticFeeData,
    invoiceState.localFeeData,
    invoiceState.inlandFeeData,
    invoiceState.otherFeeData,
    invoiceState.taxFeeData,
  ]);

  const getFinalPrice = useCallback(() => {
    return (
      getAllTotalItemPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ) +
      getAllTotalVatPriceOfFeeData(
        invoiceState.freightFeeData,
        invoiceState.domesticFeeData,
        invoiceState.localFeeData,
        invoiceState.inlandFeeData,
        invoiceState.otherFeeData,
        invoiceState.taxFeeData
      ) +
      getTaxExemptPrice()
    );
  }, [
    invoiceState.freightFeeData,
    invoiceState.domesticFeeData,
    invoiceState.localFeeData,
    invoiceState.inlandFeeData,
    invoiceState.otherFeeData,
    invoiceState.taxFeeData,
    getTaxExemptPrice,
  ]);

  const getCountryNameByPortName = useCallback(
    (portName: string) => {
      const filterPortData = portData.find((v) => {
        return v.nameEN === portName;
      });

      return filterPortData?.country || "";
    },
    [portData]
  );

  const changeExchangeRateList = useCallback(
    (currency: string, rate: number) => {
      return invoiceState.invoiceExchangeRateList.map((v) => {
        if (v.currency === currency) {
          return {
            ...v,
            rate,
          };
        }
        return {
          ...v,
        };
      });
    },
    [invoiceState.invoiceExchangeRateList]
  );

  const handleCommonInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const reducerValue = event.target.name as keyof ApplyBidFormData;

      setValue(reducerValue, event.target.value);

      if (reducerValue === "cbm" || reducerValue === "ton") {
        setRequestButtonDisabledValue(true);
      }
    },
    [setValue, setRequestButtonDisabledValue]
  );

  /**
   * 한국 사업자등록번호는 숫자만 입력 가능함
   * 해당 사업자등록번호는 저장 버튼을 눌렀을 때 검증
   */
  const handleUserBRNChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(
        "userBRN",
        bidDetailData.locale === "KR"
          ? getValueAsNumberOnly(event.target.value)
          : event.target.value
      );
    },
    [setValue, bidDetailData.locale]
  );

  const handleStartPortChange = useCallback(
    (autoCompleteValue: { value: string; label: string } | null) => {
      if (autoCompleteValue) {
        setValue("startPort", autoCompleteValue.value);

        setValue(
          "startCountryName",
          getCountryNameByPortName(autoCompleteValue.value)
        );
      }
      return;
    },
    [setValue, getCountryNameByPortName]
  );

  const handleEndPortChange = useCallback(
    (autoCompleteValue: { value: string; label: string } | null) => {
      if (autoCompleteValue) {
        setValue("endPort", autoCompleteValue.value);

        setValue(
          "endCountryName",
          getCountryNameByPortName(autoCompleteValue.value)
        );
      }
      return;
    },
    [setValue, getCountryNameByPortName]
  );

  const handleExchangeListChange = useCallback(
    (rate: number, currency: string) => {
      setValue(
        "invoiceExchangeRateList",
        changeExchangeRateList(currency, rate)
      );

      setRequestButtonDisabledValue(true);
    },
    [changeExchangeRateList, setValue, setRequestButtonDisabledValue]
  );

  const getAmountByInvoiceType = useCallback((amount: number) => {
    return Math.round(amount * 1000) / 1000;
  }, []);

  const getReCalculatedAmountByItemUnitMeasurement = useCallback(
    (feeData: TradingStatementElem) => {
      // 거래명세서에서 rton이 수량으로 들어가기 때문에 계산한다.
      const amount = calculateRTonForTradingStatement({
        shipmentDetailData: bidDetailData,
        cbm: invoiceState.cbm,
        ton: invoiceState.ton,
        directAirRton,
      });

      if (feeData.itemUnitMeasurement === "R.TON") {
        return amount < 1 ? 1 : getAmountByInvoiceType(amount);
      }

      if (feeData.itemUnitMeasurement === "C/W") {
        return amount;
      }

      if (feeData.itemUnitMeasurement === "0.1 R.TON") {
        return Math.round(amount * 10);
      }

      if (feeData.itemUnitMeasurement === "B/L") {
        return 1;
      }

      return feeData.amount ?? 0;
    },
    [
      bidDetailData,
      directAirRton,
      getAmountByInvoiceType,
      invoiceState.cbm,
      invoiceState.ton,
    ]
  );

  const getReCalculatedUnitPriceByExchangeDataRate = useCallback(
    (exchangeDataList: ExchangeRate[], row: TradingStatementElem) => {
      const exchangeData = exchangeDataList.find((v) => {
        return (
          v.currency === row.currency ||
          (v.currency === "CNY" && row.currency === "CNY")
        );
      });

      if (row.currency === "KRW" || !exchangeData) {
        return row.unitPrice;
      }
      return multiplyByBigNumber(row.unitPrice, exchangeData.rate);
    },
    []
  );

  const getReCalculatedItemPriceByItemUnitMeasurement = useCallback(
    (feeData: TradingStatementElem, amount: number) => {
      const unitPrice = getReCalculatedUnitPriceByExchangeDataRate(
        invoiceState.invoiceExchangeRateList,
        feeData
      );

      if (feeData.itemUnitMeasurement === "R.TON") {
        if (bidDetailData.locale === "SG") {
          const itemPrice =
            amount < 1
              ? Math.ceil(unitPrice)
              : Math.ceil(multiplyByBigNumber(unitPrice, amount));

          // SG의 경우 소숫점 두자리까지 표시 정일경우 .00까지 표시
          return Number.isInteger(itemPrice)
            ? Number(`${itemPrice}.00`)
            : Number(itemPrice.toFixed(2));
        }
        return amount < 1
          ? Math.ceil(unitPrice)
          : Math.ceil(multiplyByBigNumber(unitPrice, amount));
      }

      if (feeData.itemUnitMeasurement === "0.1 R.TON") {
        if (bidDetailData.locale === "SG") {
          const itemPrice = multiplyByBigNumber(unitPrice, Math.round(amount));

          return Number.isInteger(itemPrice)
            ? Number(`${itemPrice}.00`)
            : Number(itemPrice.toFixed(2));
        }
        return Math.ceil(multiplyByBigNumber(unitPrice, Math.round(amount)));
      }

      // 직접 계산된 RTON이 amount에 반영됨
      if (feeData.itemUnitMeasurement === "C/W") {
        if (bidDetailData.locale === "SG") {
          const itemPrice = multiplyByBigNumber(unitPrice, amount);
          return Number.isInteger(itemPrice)
            ? Number(`${itemPrice}.00`)
            : Number(itemPrice.toFixed(2));
        }
        return Math.ceil(multiplyByBigNumber(unitPrice, amount));
      }

      if (bidDetailData.locale === "SG") {
        const itemPrice = multiplyByBigNumber(unitPrice, amount ?? 0);
        return Number.isInteger(itemPrice)
          ? Number(`${itemPrice}.00`)
          : Number(itemPrice.toFixed(2));
      }

      return Math.ceil(multiplyByBigNumber(unitPrice, amount ?? 0));
    },
    [
      bidDetailData,
      getReCalculatedUnitPriceByExchangeDataRate,
      invoiceState.invoiceExchangeRateList,
    ]
  );

  const getReCalculatedFeeData = useCallback(
    (feeData: TradingStatementElem[]) => {
      return feeData.map((v) => {
        const amount = getReCalculatedAmountByItemUnitMeasurement(v);

        return {
          ...v,
          amount,
          itemPrice: getReCalculatedItemPriceByItemUnitMeasurement(v, amount),
        };
      });
    },
    [
      getReCalculatedAmountByItemUnitMeasurement,
      getReCalculatedItemPriceByItemUnitMeasurement,
    ]
  );

  const handleRecalculateInvoiceItemValueClick = useCallback(() => {
    if (bidDetailData.freightType === "AIR" && !directAirRton) {
      alert("RTON을 입력해주세요.");

      return;
    }

    const newDomesticFeeData = getReCalculatedFeeData(
      invoiceState.domesticFeeData
    );
    const newFreightFeeData = getReCalculatedFeeData(
      invoiceState.freightFeeData
    );
    const newInlandFeeData = getReCalculatedFeeData(invoiceState.inlandFeeData);
    const newLocalFeeData = getReCalculatedFeeData(invoiceState.localFeeData);
    const newOtherFeeData = getReCalculatedFeeData(invoiceState.otherFeeData);
    const newTaxFeeData = getReCalculatedFeeData(invoiceState.taxFeeData);

    setValue("freightFeeData", newFreightFeeData);
    setValue("domesticFeeData", newDomesticFeeData);
    setValue("inlandFeeData", newInlandFeeData);
    setValue("localFeeData", newLocalFeeData);
    setValue("otherFeeData", newOtherFeeData);
    setValue("taxFeeData", newTaxFeeData);

    setRequestButtonDisabledValue(false);
  }, [
    bidDetailData.freightType,
    directAirRton,
    getReCalculatedFeeData,
    invoiceState.domesticFeeData,
    invoiceState.freightFeeData,
    invoiceState.inlandFeeData,
    invoiceState.localFeeData,
    invoiceState.otherFeeData,
    invoiceState.taxFeeData,
    setRequestButtonDisabledValue,
    setValue,
  ]);

  const ContainerNumber = useMemo(() => {
    if (!bidDetailData.containersNumberInfo) {
      return "-";
    }
    let dry20 = 0;
    let dry40 = 0;
    let hq40 = 0;

    bidDetailData.containersNumberInfo.forEach((v) => {
      if (v.includes("20DRY")) {
        dry20 += 1;
      }
      if (v.includes("40DRY")) {
        dry40 += 1;
      }
      if (v.includes("40HQ")) {
        hq40 += 1;
      }
    });

    return (
      <div>
        {dry20 > 0 && <div>20DRY X {dry20}</div>}
        {dry40 > 0 && <div>40DRY X {dry40}</div>}
        {hq40 > 0 && <div>40HQ X {hq40}</div>}
      </div>
    );
  }, [bidDetailData.containersNumberInfo]);

  /**
   * 관부가세 합계 필드의 금액
   * 수입 세금 섹션 항목의 합을 사용함
   */
  const getTotalTaxFee = () => {
    const { taxFeeData } = invoiceState;

    if (!taxFeeData || !taxFeeData.length) return 0;

    const total = taxFeeData.reduce((acc, { itemPrice }) => acc + itemPrice, 0);

    return total;
  };

  return (
    <>
      <AdminDetailDescription
        title={"기본정보"}
        descriptionValueList={[
          {
            label: "B/L NO",
            gridSize: 4,
            value: invoiceState.blNumber,
            labelGridSize: 3,
          },
          {
            label: "회사명",
            labelGridSize: 3,
            gridSize: 4,
            value: (
              <TextField
                name="recipient"
                value={invoiceState.recipient}
                onChange={handleCommonInputChange}
                label="회사명"
                size="small"
                sx={{ marginTop: -1 }}
                fullWidth
              />
            ),
          },
          {
            label: "사업자등록번호",
            labelGridSize: 3,
            gridSize: 4,
            value: (
              <TextField
                value={invoiceState.userBRN}
                onChange={handleUserBRNChange}
                label="사업자등록번호"
                size="small"
                sx={{ marginTop: -1 }}
                fullWidth
              />
            ),
          },
          ...(bidDetailData.freightType !== "AIR"
            ? [
                {
                  label: "VSL / VYG",
                  labelGridSize: 3,
                  gridSize: 4,
                  value: (
                    <>
                      <TextField
                        value={invoiceState.shipName}
                        onChange={handleCommonInputChange}
                        label="VSL"
                        size="small"
                        name="shipName"
                        sx={{ marginTop: -1 }}
                        fullWidth
                      />

                      <TextField
                        value={invoiceState.voyageNo}
                        onChange={handleCommonInputChange}
                        label="VYG"
                        size="small"
                        name="voyageNo"
                        fullWidth
                      />
                    </>
                  ),
                },
              ]
            : []),
          {
            label: "PORT OF LOADING",
            labelGridSize: 3,
            gridSize: 4,
            value: (
              <Autocomplete
                size="small"
                onChange={(event, newValue) => handleStartPortChange(newValue)}
                value={{
                  value: invoiceState.startPort,
                  label: invoiceState.startPort,
                }}
                options={portDataForAutoCompleteOptions}
                renderInput={(params) => (
                  <TextField {...params} label={"PORT OF LOADING"} />
                )}
              />
            ),
          },
          {
            label: "PORT OF DISCHARGE",
            labelGridSize: 3,
            gridSize: 4,
            value: (
              <Autocomplete
                size="small"
                onChange={(event, newValue) => handleEndPortChange(newValue)}
                value={{
                  value: invoiceState.endPort,
                  label: invoiceState.endPort,
                }}
                options={portDataForAutoCompleteOptions}
                renderInput={(params) => (
                  <TextField {...params} label={"PORT OF DISCHARGE"} />
                )}
              />
            ),
          },
          {
            label: "Arrival date",
            labelGridSize: 3,
            gridSize: 4,
            value:
              bidDetailData.locale === "SG" || !bidDetailData.isImport ? (
                <DatePicker
                  isNotISOString={true}
                  when="start"
                  value={invoiceState.arrivalDate}
                  setDate={(date) => {
                    setValue("arrivalDate", date ?? "");
                  }}
                />
              ) : (
                invoiceState.arrivalDate
              ),
          },
          {
            label: "INCOTERMS",
            labelGridSize: 3,
            gridSize: 4,
            value: bidDetailData.incoterms,
          },
          {
            label: "MODE",
            labelGridSize: 3,
            gridSize: 4,
            value: bidDetailData.freightType,
          },
          {
            label: "수량",
            labelGridSize: 3,
            gridSize: 4,
            value: ContainerNumber,
          },
          {
            label: "CNTR",
            labelGridSize: 1.5,
            gridSize: 8,
            value:
              bidDetailData.locale === "SG" || !bidDetailData.isImport ? (
                <ContainersNumberTextField control={control} />
              ) : bidDetailData.containersNumberInfo ? (
                <ContainerList data={bidDetailData.containersNumberInfo} />
              ) : (
                "-"
              ),
          },
          {
            label: "PACKAGE",
            labelGridSize: 3,
            gridSize: 4,
            value: (
              <TextField
                name="packageValue"
                value={invoiceState.packageValue}
                onChange={handleCommonInputChange}
                label="PACKAGE"
                size="small"
                sx={{ marginTop: -1 }}
                fullWidth
              />
            ),
          },
          ...(bidDetailData.freightType !== "AIR"
            ? [
                {
                  label: "CBM",
                  labelGridSize: 3,
                  gridSize: 4,
                  value: (
                    <TextField
                      name="cbm"
                      value={invoiceState.cbm}
                      onChange={handleCommonInputChange}
                      label="CBM"
                      size="small"
                      sx={{ marginTop: -1 }}
                      fullWidth
                    />
                  ),
                },
              ]
            : []),

          {
            label: "KG(G.W)",
            labelGridSize: 3,
            gridSize: 4,
            value: (
              <TextField
                name="ton"
                value={invoiceState.ton}
                onChange={handleCommonInputChange}
                label="KG(G.W)"
                size="small"
                sx={{ marginTop: -1 }}
                fullWidth
              />
            ),
          },
          {
            label: bidDetailData.freightType === "AIR" ? "C.W" : "R.TON",
            labelGridSize: 3,
            gridSize: 4,
            value:
              bidDetailData.freightType === "AIR" ? (
                <NumericFormat
                  label="R.TON"
                  thousandSeparator={true}
                  decimalScale={3}
                  size="small"
                  sx={{ marginTop: -1 }}
                  customInput={TextField}
                  value={directAirRton}
                  onValueChange={(value) => {
                    handleDirectAirRtonChange(value?.floatValue);
                  }}
                />
              ) : (
                calculateRTonForTradingStatement({
                  shipmentDetailData: bidDetailData,
                  cbm: invoiceState.cbm,
                  ton: invoiceState.ton,
                  directAirRton,
                })
              ),
          },
          ...invoiceState.invoiceExchangeRateList
            .map((v, i) => {
              return {
                label: v.currency,
                labelGridSize: 3,
                gridSize: 4,
                value: (
                  <TextField
                    type="number"
                    value={v.rate}
                    onChange={(e) => {
                      handleExchangeListChange(
                        parseFloat(e.target.value),
                        v.currency
                      );
                    }}
                    label={v.currency}
                    size="small"
                    sx={{ marginTop: -1 }}
                    fullWidth
                  />
                ),
              };
            })
            .filter((v) => {
              return v.label !== "KRW";
            }),

          {
            label: "최종금액",
            labelGridSize: 1,
            gridSize: 12,
            value: toThousandUnitFormat(getFinalPrice()),
          },
          {
            label: "코멘트",
            labelGridSize: 1,
            gridSize: 12,
            value: (
              <TextField
                name="comment"
                value={invoiceState.comment}
                onChange={handleCommonInputChange}
                label="코멘트"
                size="small"
                sx={{ marginTop: -1 }}
                fullWidth
                multiline={true}
                rows={4}
              />
            ),
          },
        ]}
      />

      <AdminDetailDescription
        title={"금액"}
        descriptionValueList={[
          {
            label: "갱신",
            gridSize: 4,
            value: (
              <Button
                onClick={handleRecalculateInvoiceItemValueClick}
                variant="contained"
              >
                갱신
              </Button>
            ),
          },
          {
            label: "영세 과세 합계",
            gridSize: 4,
            /** 영세 과세 합계 값에서 관부가세 합계 값을 빼야함 (#13910) */
            value: toThousandUnitFormat(getTotalPrice() - getTotalTaxFee()),
          },
          {
            label: "부가세",
            gridSize: 4,
            value: toThousandUnitFormat(getVatPrice()),
          },
          {
            label: "비과세",
            gridSize: 4,
            value: toThousandUnitFormat(getTaxExemptPrice()),
          },
          {
            label: "관부가세 합계",
            gridSize: 4,
            value: toThousandUnitFormat(getTotalTaxFee()),
          },
          {
            label: "최종금액",
            gridSize: 4,
            value: toThousandUnitFormat(getFinalPrice()),
          },
        ]}
      />
    </>
  );
};

export default TradingStateDescriptions;
