import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import {
  RequestInvoiceData,
  SAVE_INVOICE_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";

function TemporarySaveButton({
  invoiceType,
  getInvoiceDataForRequest,
  handlerErrorRequest,
  setSuccessMessage,
  setShowsSuccessSnackBar,
  bidDetailData,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: (isTemporary: boolean) => RequestInvoiceData;
  handlerErrorRequest: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  setSuccessMessage: (value: React.SetStateAction<string>) => void;
  setShowsSuccessSnackBar: (value: React.SetStateAction<boolean>) => void;
  bidDetailData: TrelloBidDetail;
}) {
  const queryClient = useQueryClient();

  const invoiceId =
    sessionStorage.getItem("invoiceId") !== "none"
      ? Number(sessionStorage.getItem("invoiceId"))
      : undefined;

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const isExport = !bidDetailData.isImport;

  const handleRequestSuccess = useCallback(
    ({ data }: { data: SAVE_INVOICE_RES }) => {
      if (invoiceId) {
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
            invoiceId,
          })
        );
      } else {
        sessionStorage.setItem("invoiceId", data.id.toString());
      }

      if (isExport) {
        queryClient.invalidateQueries(
          TRELLO_BID_QUERY_KEY_GEN.getExportTrelloDetail({
            bidId: bidDetailData.id,
          })
        );
      }

      setSuccessMessage("임시저장했습니다.");
      setShowsSuccessSnackBar(true);
    },
    [
      bidDetailData.id,
      invoiceId,
      isExport,
      queryClient,
      setShowsSuccessSnackBar,
      setSuccessMessage,
    ]
  );

  const handleTemporarySaveClick = useCallback(() => {
    if (invoiceId) {
      editInvoice(
        {
          invoiceData: getInvoiceDataForRequest(true),
          isTemporary: true,
          invoiceType,
        },
        {
          onSuccess: handleRequestSuccess,
          onError: ({ response }) => {
            handlerErrorRequest(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(true),
          isTemporary: true,
          invoiceType,
        },
        {
          onSuccess: handleRequestSuccess,
          onError: ({ response }) => {
            handlerErrorRequest(response);
          },
        }
      );
    }
  }, [
    editInvoice,
    getInvoiceDataForRequest,
    handleRequestSuccess,
    handlerErrorRequest,
    invoiceId,
    invoiceType,
    saveInvoice,
  ]);

  return (
    <Button
      disabled={saveInvoiceLoading || editInvoiceLoading}
      variant="outlined"
      onClick={handleTemporarySaveClick}
    >
      {saveInvoiceLoading || editInvoiceLoading ? (
        <CircularProgress />
      ) : (
        "임시저장"
      )}
    </Button>
  );
}

export default TemporarySaveButton;
