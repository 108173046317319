import { Box, Button } from "@mui/material";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";
import DatePicker from "@sellernote/_shared-for-forwarding-admin/src/components/DatePicker";

function UpdateWithdrawDate({
  checkBoxArr,
  initCheckBoxSet,
  refetchPurchaseListData,
}: {
  checkBoxArr: number[];
  initCheckBoxSet: (values?: number[]) => void;
  refetchPurchaseListData: () => void;
}) {
  const { mutate: editWithdrawDate } =
    ADMIN_SETTLEMENT_QUERY.useEditWithdrawDate();

  const {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  const handleWithdrawDateUpdate = (date: string | null) => {
    editWithdrawDate(
      { requestIds: checkBoxArr, withdrawalDate: date ? date : "" },
      {
        onSuccess: () => {
          initCheckBoxSet();
          setShowsSuccessSnackBar(true);
          refetchPurchaseListData();
        },

        onError: (error) => {
          if (error.response?.data.errorCode === "E067") {
            setErrorMessage("취소 권한이 없습니다.");
            setShowsErrorSnackBar(true);
            return;
          }

          if (error.response?.data.errorCode === "E079") {
            setErrorMessage(
              "입금증, 이체증이 있으면 출금 취소가 불가능합니다."
            );
            setShowsErrorSnackBar(true);
            return;
          }
          setErrorMessage("출금일 변경을 할 수 없습니다.");
          setShowsErrorSnackBar(true);
        },
      }
    );
  };

  return (
    <Box sx={{ display: "flex", gap: "4px" }}>
      <DatePicker
        value={null}
        setDate={(date) => handleWithdrawDateUpdate(date)}
        when={"start"}
        disabled={checkBoxArr.length < 1}
      />

      <Button
        variant="outlined"
        color="error"
        disabled={checkBoxArr.length < 1}
        onClick={() => handleWithdrawDateUpdate(null)}
      >
        출금일 초기화
      </Button>

      {UploadResponseSnackBar}
    </Box>
  );
}

export default UpdateWithdrawDate;
