import { useState } from "react";

import { Country } from "@sellernote/_shared/src/types/common/common";

import { PartnerForm } from "./types";

import ActionButton from "./ActionButton";
import Form from "./Form";
import InvalidAlert from "./InvalidAlert";

export default function RegisterPartnerForm({
  countryData,
  setShowsRegisterForm,
  handlePartnerListRefetch,
}: {
  countryData: Country[];
  setShowsRegisterForm: (val: boolean) => void;
  handlePartnerListRefetch: () => void;
}) {
  const [form, setForm] = useState<PartnerForm>({
    name: "", // 회사명
    businessArea: "", // 파트너 유형
    transportModeList: [], // 운송 유형
    serviceRegion: "", // 서비스 지역
    language: "", // 언어
    countryInChargeList: [], // 담당 국가
    managerName: "", // 담당자 이름
    position: "", // 직함
    phone: "", // 전화번호
    email: "", // 이메일
    monthCalculate: false, // 월 정산 여부
    bank: "", // 은행
    accountNumber: "", // 계좌번호
    isBill: false, // 계산서 발행 여부
    brn: "", // 사업자 등록번호
  });

  const [showsInvalidFormAlert, setShowsInvalidFormAlert] = useState(false);

  return (
    <>
      <Form form={form} setForm={setForm} countryData={countryData} />

      <ActionButton
        form={form}
        setShowsRegisterForm={setShowsRegisterForm}
        setShowsInvalidFormAlert={setShowsInvalidFormAlert}
        handlePartnerListRefetch={handlePartnerListRefetch}
      />

      <InvalidAlert
        showsInvalidFormAlert={showsInvalidFormAlert}
        setShowsInvalidFormAlert={setShowsInvalidFormAlert}
        countryInChargeList={form.countryInChargeList}
        serviceRegion={form.serviceRegion}
        language={form.language}
        name={form.name}
        transportModeList={form.transportModeList}
        businessArea={form.businessArea}
        managerName={form.managerName}
        email={form.email}
      />
    </>
  );
}
