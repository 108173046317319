import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Input, Row, Select, Table } from "antd";

import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

const { Option } = Select;
const InputGroup = Input.Group;

const PortList = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const history = useHistory();

  const handleChangeFilters = (pagination: any, filters: any) => {
    setCurrentPage(pagination.current);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "80",
    },
    {
      title: "Code",
      dataIndex: "code",
    },
    {
      title: "이름",
      dataIndex: "name",
    },
    {
      title: "이름2",
      dataIndex: "nameC",
    },
    {
      title: "영문이름",
      dataIndex: "nameEN",
    },
    {
      title: "국가명",
      dataIndex: "countryName",
    },
    {
      title: "타입",
      dataIndex: "type",
      render: (d: string) => {
        return d === "sea" ? "해상" : "항공";
      },
    },
  ];

  return (
    <Layout breadcrumbs={["항구관리"]} title="항구관리">
      <Card>
        <Row justify="end" align="middle" style={{ marginBottom: "10px" }}>
          <Row
            justify="space-between"
            align="middle"
            style={{ minWidth: "50%" }}
          ></Row>
        </Row>

        <Table
          rowKey={(record) => record.id.toString()}
          dataSource={portList}
          columns={columns}
          pagination={{ pageSize: 25, showSizeChanger: false }}
          onChange={handleChangeFilters}
        />
      </Card>
    </Layout>
  );
};

export default withRequireAuth(PortList);
