import { useState } from "react";
import { Box, Button } from "@mui/material";

import { Country } from "@sellernote/_shared/src/types/common/common";

import RegisterPartnerForm from "./RegisterPartnerForm";

const RegisterPartner = ({
  countryData,
  handlePartnerListRefetch,
}: {
  countryData: Country[];
  handlePartnerListRefetch: () => void;
}) => {
  const [showsRegisterForm, setShowsRegisterForm] = useState(false);

  if (!showsRegisterForm)
    return (
      <Box sx={{ mb: 5, mt: 2 }}>
        <Button variant="contained" onClick={() => setShowsRegisterForm(true)}>
          등록하기
        </Button>
      </Box>
    );

  return (
    <RegisterPartnerForm
      countryData={countryData}
      setShowsRegisterForm={setShowsRegisterForm}
      handlePartnerListRefetch={handlePartnerListRefetch}
    />
  );
};

export default RegisterPartner;
