import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import {
  RequestInvoiceData,
  SAVE_INVOICE_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import {
  ResponseFailureInfo,
  SellernoteAppRegion,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { validateUEN } from "@sellernote/_shared/src/utils/common/validation";

function SaveAndDownloadButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  setSuccessMessage,
  setShowsSuccessSnackBar,
  setErrorMessage,
  setShowsErrorSnackBar,
  invoiceState,
  region,
  bidDetailData,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: (isTemporary: boolean) => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  setSuccessMessage: (value: React.SetStateAction<string>) => void;
  setShowsSuccessSnackBar: (value: React.SetStateAction<boolean>) => void;
  setErrorMessage: (value: React.SetStateAction<string>) => void;
  setShowsErrorSnackBar: (value: React.SetStateAction<boolean>) => void;
  invoiceState: ApplyBidFormData;
  region: SellernoteAppRegion;
  bidDetailData: TrelloBidDetail;
}) {
  const queryClient = useQueryClient();

  const invoiceId =
    sessionStorage.getItem("invoiceId") !== "none"
      ? Number(sessionStorage.getItem("invoiceId"))
      : undefined;

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { mutate: getFileDownloadUrl } =
    ADMIN_FILE_QUERY.useGetFileDownloadUrl();

  const isExport = !bidDetailData.isImport;

  const handleRequestSuccess = useCallback(
    ({ data }: { data: SAVE_INVOICE_RES }) => {
      getFileDownloadUrl(
        {
          pathParams: {
            key: data.pdfKey,
          },
        },
        {
          onSuccess: ({ data: successData }) => {
            window.location.href = successData.url;

            if (invoiceId) {
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                  invoiceId,
                })
              );
            } else {
              sessionStorage.setItem("invoiceId", data.id.toString());
            }

            if (isExport) {
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.getExportTrelloDetail({
                  bidId: bidDetailData.id,
                })
              );
            }

            setSuccessMessage("저장했습니다.");
            setShowsSuccessSnackBar(true);
          },
          onError: () => {
            setErrorMessage("파일 다운로드에 실패했습니다.");
            setShowsErrorSnackBar(true);
          },
        }
      );
    },
    [
      bidDetailData.id,
      getFileDownloadUrl,
      invoiceId,
      isExport,
      queryClient,
      setErrorMessage,
      setShowsErrorSnackBar,
      setShowsSuccessSnackBar,
      setSuccessMessage,
    ]
  );

  const handleSaveAndDownloadClick = useCallback(() => {
    if (region === "SG" && !validateUEN(invoiceState.userBRN).isValid) {
      setErrorMessage("정상적인 싱가포르 사업자등록번호가 아닙니다.");
      setShowsErrorSnackBar(true);

      return;
    }

    if (invoiceState.userBRN.length !== 10) {
      setErrorMessage("정상적인 사업자등록번호가 아닙니다.");
      setShowsErrorSnackBar(true);

      return;
    }
    if (invoiceId) {
      editInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: handleRequestSuccess,
          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: handleRequestSuccess,
          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    }
  }, [
    region,
    invoiceState.userBRN,
    invoiceId,
    setErrorMessage,
    setShowsErrorSnackBar,
    editInvoice,
    getInvoiceDataForRequest,
    invoiceType,
    handleRequestSuccess,
    handleRequestError,
    saveInvoice,
  ]);

  return (
    <Button
      disabled={saveInvoiceLoading || editInvoiceLoading}
      variant="contained"
      onClick={handleSaveAndDownloadClick}
    >
      {saveInvoiceLoading || editInvoiceLoading ? (
        <CircularProgress />
      ) : (
        "저장&다운로드"
      )}
    </Button>
  );
}

export default SaveAndDownloadButton;
