import { Box, Button } from "@mui/material";

import PARTNER_QUERY from "@sellernote/_shared/src/queries/forwarding/PARTNER_QUERY";
import { getEmailValidation } from "@sellernote/_shared/src/utils/common/validation";

import { PartnerForm } from "./types";

export default function ActionButton({
  form,
  setShowsRegisterForm,
  setShowsInvalidFormAlert,
  handlePartnerListRefetch,
}: {
  form: PartnerForm;
  setShowsRegisterForm: (val: boolean) => void;
  setShowsInvalidFormAlert: (val: boolean) => void;
  handlePartnerListRefetch: () => void;
}) {
  const {
    mutate: registerPartner,
    ResponseHandler: ResponseHandlerOfRegisterPartner,
  } = PARTNER_QUERY.useRegisterPartner({});

  const {
    name,
    businessArea,
    transportModeList,
    serviceRegion,
    language,
    countryInChargeList,
    managerName,
    position,
    phone,
    email,
    monthCalculate,
    bank,
    accountNumber,
    isBill,
    brn,
  } = form;

  const isTransportModeListContainsAll = transportModeList.includes("ALL");
  const isCountryInChargeListContainsAll = countryInChargeList.includes("ALL");

  const handleRegisterPartner = () => {
    if (
      !countryInChargeList.length ||
      !language ||
      !name ||
      !transportModeList.length ||
      !businessArea ||
      !managerName ||
      !email ||
      !getEmailValidation(email).result
    ) {
      setShowsInvalidFormAlert(true);
      return;
    }

    registerPartner(
      {
        countries: isCountryInChargeListContainsAll
          ? ["ALL"]
          : countryInChargeList,
        language,
        name,
        region: serviceRegion,
        businessArea,
        transportModes: isTransportModeListContainsAll
          ? ["ALL"]
          : transportModeList,
        managerName,
        managerPosition: position,
        managerEmail: email,
        managerPhone: phone,
        settlementFlag: monthCalculate,
        bankName: !bank ? null : bank,
        accountNumber: !accountNumber ? null : accountNumber,
        invoiceIssueFlag: isBill,
        BRNNumber: !brn ? null : brn,
      },
      {
        onSuccess: () => {
          handlePartnerListRefetch();
          setShowsRegisterForm(false);
        },
      }
    );
  };

  return (
    <>
      <Box sx={{ mt: 2, mb: 2, display: "flex", gap: 3 }}>
        <Button variant="contained" onClick={handleRegisterPartner}>
          저장하기
        </Button>

        <Button
          variant="outlined"
          color="error"
          onClick={() => setShowsRegisterForm(false)}
        >
          취소
        </Button>
      </Box>

      {ResponseHandlerOfRegisterPartner}
    </>
  );
}
