import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { UPDATE_TEAM_DETAIL_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminTeam";
import ADMIN_BID_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import ADMIN_TEAM_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_TEAM_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useTeamAndUserDetail from "@sellernote/_shared-for-forwarding-admin/src/hooks/useTeamAndUserDetail";

function TeamDetail() {
  const { id }: { id: string } = useParams();

  const history = useHistory();

  const { UploadResponseSnackBar, setShowsErrorSnackBar, setErrorMessage } =
    useUploadResponseSnackBar();

  const {
    control,
    watch,
    reset,
    formState: { errors },
    getValues,
  } = useForm<UPDATE_TEAM_DETAIL_REQ>();

  const [isEditMode, setIsEditMode] = useState(false);

  const { data: teamDetail, refetch: refetchTeamDetail } =
    ADMIN_TEAM_QUERY.useGetAdminTeamDetail(Number(id));

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: adminList } = ADMIN_USER_QUERY.useGetAdminUserList();

  const { data: fullfillmentManagerData } =
    ADMIN_BID_USER_QUERY.useGetFullfillmentManagers({});

  const {
    mutate: updateTeamDetail,
    ResponseHandler: ResponseHandlerOfUpdateTeamDetail,
  } = ADMIN_TEAM_QUERY.useUpdateTeamDetail({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        refetchTeamDetail();
        reset();
        setIsEditMode(false);
      },

      customizeMessage: () => ({
        title: "팀 상세를 수정했습니다.",
      }),
    },

    failureModalInfo: {
      customizeMessage: (error) => {
        if (error?.code === 404 && error?.error === "team not found") {
          return {
            title: "삭제된 팀입니다.",
          };
        }

        return {
          title: "팀 상세 수정에서 오류가 발생했습니다.",
        };
      },
    },
  });

  const {
    mutate: deleteTeamDetail,
    ResponseHandler: ResponseHandlerOfDeleteTeam,
  } = ADMIN_TEAM_QUERY.useDeleteTeam({
    teamId: Number(id),
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        history.push("/team/list");
      },

      customizeMessage: () => ({
        title: "팀을 삭제했습니다.",
      }),
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "팀 삭제에서 오류가 발생했습니다.",
      }),
    },
  });

  const handleTeamDetailUpdate = () => {
    if (getValues().BRN?.toString().length !== 10) {
      setErrorMessage("사업자등록번호 입력을 확인해주세요.");
      setShowsErrorSnackBar(true);

      return;
    }

    updateTeamDetail({
      ...watch(),
      pathParams: {
        teamId: Number(id),
      },
    });
  };

  const handleTeamDelete = () => {
    deleteTeamDetail({});
  };

  const handleTeamDetailUpdateCancel = () => {
    reset();
    setIsEditMode(false);
  };

  const handleEditModeChange = () => {
    setIsEditMode(true);
  };

  const { Detail: TeamDetail } = useTeamAndUserDetail({
    detailData: teamDetail,
    portList,
    adminList,
    id,
    ResponseHandlerOfUpdateTeamDetail,
    handleTeamDetailUpdate,
    control,
    handleTeamDetailUpdateCancel,
    handleEditModeChange,
    isEditMode,
    type: "team",
    fullfillmentManagerData,
    refetchDetailData: refetchTeamDetail,
    handleTeamDelete,
    ResponseHandlerOfDeleteTeam,
    UploadResponseSnackBar,
  });

  return TeamDetail;
}

export default withRequireAuth(TeamDetail);
