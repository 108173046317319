import { ChangeEvent, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";

import { Country } from "@sellernote/_shared/src/types/common/common";
import { AdminBidTransportMode } from "@sellernote/_shared/src/types/forwarding/adminBid";

import useGetCountryList from "pages/partner/hooks/useGetCountryList";
import { PartnerForm } from "../types";

import { TRANSPORT_MODE_LIST } from "../../../constants/TRANSPORT_MODE_LIST";

export default function Form({
  form,
  setForm,
  countryData,
}: {
  form: PartnerForm;
  setForm: (val: PartnerForm) => void;
  countryData: Country[];
}) {
  const [countryFilterList, setCountryFilterList] = useState<string[]>([]);

  const { countryList } = useGetCountryList({
    filterList: countryFilterList,
  });

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleCountryInChargeUpdate = (
    selectedCountryList: { label: string; value: string }[]
  ) => {
    /** 선택된 국가들을 countryData에서 추출한 뒤, ["KR", "CN"]와 같은 형태로 변경 */
    const countryCode: string[] = selectedCountryList.map(({ value }) => {
      const targetCountryName = countryData.find((v: Country) => {
        return v.nameKR === value;
      });

      if (value === "ALL") return "ALL";

      if (!targetCountryName) return "";

      return targetCountryName.name;
    });

    setCountryFilterList(countryCode);

    setForm({ ...form, countryInChargeList: countryCode });
  };

  const isAllCountrySelected = form.countryInChargeList.find(
    (option) => option === "ALL"
  );

  const isAllTransportModeSelected = form.transportModeList.find(
    (option) => option === "ALL"
  );

  return (
    <Paper elevation={0} sx={{ mt: 5 }}>
      <Box
        sx={{
          width: 500,
          display: "flex",
          flexDirection: "column",
        }}
      >
        회사명
        <Input
          name="name"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
        파트너 유형
        <FormControl size="small">
          <Select
            name="businessArea"
            onChange={(e: SelectChangeEvent) => handleFormChange(e)}
          >
            <MenuItem value="foreign">해외</MenuItem>
            <MenuItem value="domestic">국내</MenuItem>
            <MenuItem value="shipDaCustoms">관세사</MenuItem>
            <MenuItem value="customs">화주자체 관세사</MenuItem>
            <MenuItem value="inland">내륙운송사</MenuItem>
            <MenuItem value="wareHouse">창고</MenuItem>
            <MenuItem value="parcel">화물택배사</MenuItem>
            <MenuItem value="shipping">선사</MenuItem>
            <MenuItem value="etc">기타</MenuItem>
          </Select>
        </FormControl>
        운송 유형
        <Autocomplete
          multiple
          size="small"
          options={isAllTransportModeSelected ? [] : TRANSPORT_MODE_LIST}
          getOptionLabel={({ label }) => label}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} />}
          onChange={(_, transportOption) => {
            setForm({
              ...form,
              transportModeList: transportOption.map(
                ({ value }) => value
              ) as AdminBidTransportMode[],
            });
          }}
        />
        서비스 지역
        <FormControl size="small">
          <Select
            name="serviceRegion"
            onChange={(e: SelectChangeEvent) => handleFormChange(e)}
          >
            <MenuItem value="KR">한국</MenuItem>
            <MenuItem value="SG">싱가포르</MenuItem>
          </Select>
        </FormControl>
        언어
        <FormControl size="small">
          <Select
            name="language"
            onChange={(e: SelectChangeEvent) => handleFormChange(e)}
          >
            <MenuItem value="kr">KR</MenuItem>
            <MenuItem value="en">EN</MenuItem>
          </Select>
        </FormControl>
        담당 국가
        <Autocomplete
          multiple
          size="small"
          options={isAllCountrySelected ? [] : countryList}
          getOptionLabel={({ label }) => label}
          filterSelectedOptions
          renderInput={(params) => <TextField {...params} />}
          onChange={(_, selectedCountry) => {
            handleCountryInChargeUpdate(selectedCountry);
          }}
        />
        담당자 이름
        <Input
          name="managerName"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
        직함
        <Input
          name="position"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
        전화번호
        <Input
          name="phone"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
        이메일
        <Input
          type="email"
          name="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              checked={form.monthCalculate}
              name="monthCalculate"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFormChange(e)
              }
            />
          }
          label="월 정산"
        />
        은행
        <Input
          name="bank"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
        계좌번호
        <Input
          name="accountNumber"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              checked={form.isBill}
              name="isBill"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFormChange(e)
              }
            />
          }
          label="계산서 미발행"
        />
        사업자 등록번호
        <Input
          name="brn"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormChange(e)}
        />
      </Box>
    </Paper>
  );
}
