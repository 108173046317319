import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import CachedIcon from "@mui/icons-material/Cached";
import { Box, Button, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import {
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_REQ_SEARCH_KIND,
  GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminSettlement";
import { BANK_NAME_FILTER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminSettlement";
import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import { FORWARDING_ADMIN_SETTLEMENT_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminSettlement";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import {
  SettlementFinancialComment,
  SettlementFinancialDepositListData,
} from "@sellernote/_shared/src/types/forwarding/adminSettlement";
import { InvoiceResult } from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { changeInvoiceResultToKr } from "@sellernote/_shared/src/utils/forwarding/adminSettlement";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import useGetCSVDownloadInfoFromQuery from "@sellernote/_shared-for-admin/src/hooks/common/useGetCSVDownloadInfoFromQuery";
import useTableHeadFilter, {
  TableHeadFilterOption,
} from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";

import TableMemoModal from "../TableMemoModal";
import MatchBidIdModal from "./MatchBidIdModal";

type CellId = keyof SettlementFinancialDepositListData;

const termSearchTypeOptions: TermSearchType<GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_REQ_SEARCH_KIND>[] =
  [
    {
      label: "입금자명",
      value: "depositName",
    },
    {
      label: "입금액",
      value: "depositAmount",
    },
  ];

const dateSearchTypeOptions: SearchWithDateTypeOption<"depositDate">[] = [
  {
    label: "입금일",
    value: "depositDate",
  },
];

const depositResultFilterOptions: TableHeadFilterOption<InvoiceResult>[] = [
  { label: "일치", value: "complete" },
  { label: "미수", value: "unpaid" },
  { label: "초과", value: "over" },
];

function DepositTable() {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const setRequestAmount = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_REQUEST_AMOUNT
  );

  const setRequestInvoiceIds = useSetRecoilState(
    FORWARDING_ADMIN_SETTLEMENT_ATOMS.DEPOSIT_INVOICE_ID
  );

  const history = useHistory();

  const [showsMatchBidIdModal, setShowsMatchBidIdModal] = useState(false);
  const [showsTableMemoModal, setShowsTableMemoModal] = useState(false);
  const [companyId, setCompanyId] = useState(0);
  const [paymentInvoiceId, setPaymentInvoiceId] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositName, setDepositName] = useState("");
  const [perPage, setPerPage] = useState(25);
  const [currency, setCurrency] = useState<Currency>("KRW");

  const { FilterPanel: BankNameFilterPanel, filter: bankNameFilterData } =
    useTableHeadFilter({
      filterOptions: BANK_NAME_FILTER_OPTION_LIST,
    });

  const {
    FilterPanel: DepositResultFilterPanel,
    filter: depositResultFilterData,
  } = useTableHeadFilter({
    filterOptions: depositResultFilterOptions,
  });

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { data: settlementDepositData, refetch: refetchSettlementDepositList } =
    ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementDepositList({
      page: currentPage,
      perPage,
      isExcel: false,
      //쉽다 어드민 API에서는 단일값이므로 배열의 첫값을 사용
      bankName: bankNameFilterData ? bankNameFilterData[0] : undefined,
      ...(depositResultFilterData ? { depositResultFilterData } : {}),
      ...objectWithTermSearchTypeKey,
      ...(dateSearchType && startDate && endDate
        ? {
            fromDate: new Date(startDate),
            toDate: new Date(endDate),
          }
        : {}),
    });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: ADMIN_SETTLEMENT_QUERY.useGetAdminSettlementDepositExcelList,
    queryArgs: [
      {
        isExcel: true,
        bankName: bankNameFilterData ? bankNameFilterData[0] : undefined,
        ...(depositResultFilterData ? { depositResultFilterData } : {}),
        ...objectWithTermSearchTypeKey,
        ...(dateSearchType && startDate && endDate
          ? {
              fromDate: new Date(startDate),
              toDate: new Date(endDate),
            }
          : {}),
      },
    ],
  });

  const handleMatchBidIdModalOpen = useCallback(
    (settlementFinancialDepositData: SettlementFinancialDepositListData) => {
      return () => {
        setDepositAmount(settlementFinancialDepositData.depositAmount);
        setCompanyId(settlementFinancialDepositData.companyId || 0);
        setPaymentInvoiceId(settlementFinancialDepositData.id);
        setRequestInvoiceIds(settlementFinancialDepositData.invoiceIds);
        setRequestAmount(settlementFinancialDepositData.requestAmount);
        setShowsMatchBidIdModal(true);
      };
    },
    [setRequestAmount, setRequestInvoiceIds]
  );

  const handleTableMemoModalOpen = (
    settlementFinancialDepositData: SettlementFinancialDepositListData
  ) => {
    return () => {
      setDepositAmount(settlementFinancialDepositData.depositAmount);
      setPaymentInvoiceId(settlementFinancialDepositData.id);
      setDepositName(settlementFinancialDepositData.depositName);
      setShowsTableMemoModal(true);
      setCurrency(settlementFinancialDepositData.currency);
    };
  };

  const getMatchingBidIdButton = useCallback(
    (
      type: "register" | "edit",
      listData: SettlementFinancialDepositListData
    ) => {
      return (
        <Button
          onClick={handleMatchBidIdModalOpen(listData)}
          variant={type === "edit" ? "text" : "contained"}
          size="small"
        >
          {type === "edit" ? "수정" : "등록"}
        </Button>
      );
    },
    [handleMatchBidIdModalOpen]
  );

  const changeBidIdDataForTable = useCallback(
    (listData: SettlementFinancialDepositListData, isExcel: boolean) => {
      if (isExcel) {
        if (listData.bidId) {
          return listData.bidId.toString();
        }
        return "-";
      }

      if (listData.bidId) {
        const bidIdButtons = listData.bidId.map((bidId) => {
          return (
            <Button
              key={bidId}
              onClick={() => {
                setTrelloCardId(bidId);
                history.push(`/bid/detail/${bidId}`);
              }}
              variant="text"
              size="small"
            >
              {bidId}
            </Button>
          );
        });

        return (
          <>
            {bidIdButtons}

            {getMatchingBidIdButton("edit", listData)}
          </>
        );
      }

      return getMatchingBidIdButton("register", listData);
    },
    [getMatchingBidIdButton, history, setTrelloCardId]
  );

  const changeCommentDataForExcel = useCallback(
    (commentData: SettlementFinancialComment[] | null) => {
      if (!commentData) {
        return "-";
      }
      return commentData
        .map((commentItem) => {
          return Object.values(commentItem).toString();
        })
        .toString();
    },
    []
  );

  const mapDataForTable = useCallback(
    (
      data: GET_ADMIN_SETTLEMENT_DEPOSIT_LIST_RES | undefined,
      isExcel: boolean
    ) => {
      if (!data?.list) return [];

      return data?.list.map((v) => {
        const row: TableBodyRow<CellId> = {
          transactionDateTime: v.transactionDateTime,
          bankName: v.bankName,
          depositName: v.depositName,
          currency: v.currency,
          depositAmount: `${toThousandUnitFormat(v.depositAmount)}`,
          giroBankName: v.giroBankName,
          bidId: changeBidIdDataForTable(v, isExcel),
          requestAmount: `${toThousandUnitFormat(v.requestAmount)}`,
          depositResult: changeInvoiceResultToKr(v.depositResult),
          comment: isExcel ? (
            changeCommentDataForExcel(v.comment)
          ) : (
            <Button onClick={handleTableMemoModalOpen(v)}>
              {v.comment ? "확인" : "등록"}
            </Button>
          ),
        };
        return row;
      });
    },
    [changeBidIdDataForTable, changeCommentDataForExcel]
  );

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "transactionDateTime",
        disablePadding: false,
        label: "일시",
        width: 100,
      },
      {
        id: "bankName",
        disablePadding: false,
        label: "수취은행",
        width: 150,
        filter: BankNameFilterPanel,
      },
      {
        id: "depositName",
        disablePadding: false,
        label: "입금자명",
        width: 100,
      },
      {
        id: "currency",
        disablePadding: false,
        label: "통화",
        width: 100,
      },
      {
        id: "depositAmount",
        disablePadding: false,
        label: "입금액",
        numeric: true,
        width: 100,
      },
      {
        id: "account",
        disablePadding: false,
        label: "송금은행",
        width: 150,
      },
      {
        id: "bidId",
        disablePadding: false,
        label: "의뢰번호",
        width: 100,
      },
      {
        id: "requestAmount",
        disablePadding: false,
        label: "청구금액",
        numeric: true,
        width: 100,
      },
      {
        id: "depositResult",
        disablePadding: false,
        label: "결과",
        width: 100,
        filter: DepositResultFilterPanel,
      },
      {
        id: "comment",
        disablePadding: false,
        label: "메모",
        width: 100,
      },
    ];
  }, [BankNameFilterPanel, DepositResultFilterPanel]);

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload, true);
  }, [dataForCSVDownload, mapDataForTable]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(settlementDepositData, false);
  }, [settlementDepositData, mapDataForTable]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 1,
          px: 1,
          background: "#fff",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          {TermSearchPanel}
          {DateSearchPanel}
        </Box>
        <Button
          variant="contained"
          endIcon={<CachedIcon />}
          onClick={() => refetchSettlementDepositList()}
          sx={{ height: "40px" }}
        >
          갱신
        </Button>
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <Table
          headCells={headCells}
          rows={rows}
          toolbarItems={{
            left: [
              <Typography key="total">
                총 {settlementDepositData?.total || 0}건
              </Typography>,
            ],
          }}
          pagination={{
            rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
            totalCount: settlementDepositData?.total || 0,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
          }}
          csvDownloadInfo={{
            filename: `입금 리스트(${toFormattedDate(
              new Date(),
              "YYYY-MM-DD-HHmmss"
            )})`,
            scope: "all",
            isCSVDownloadRequested,
            setIsCSVDownloadRequested,
            rowsForCSVDownload,
            ResponseHandlerOfFetchDataForCSVDownload,
            statusOfQueryOfFetchDataForCSVDownload,
            removeQueryOfFetchDataForCSVDownload,
          }}
        />
      </Box>

      {showsMatchBidIdModal && (
        <MatchBidIdModal
          showsMatchBidIdModal={showsMatchBidIdModal}
          setShowsMatchBidIdModal={setShowsMatchBidIdModal}
          companyId={companyId}
          paymentInvoiceId={paymentInvoiceId}
          setCompanyId={setCompanyId}
          depositAmount={depositAmount}
          refetchSettlementDepositList={refetchSettlementDepositList}
        />
      )}

      {showsTableMemoModal && (
        <TableMemoModal
          showsTableMemoModal={showsTableMemoModal}
          setShowsTableMemoModal={setShowsTableMemoModal}
          name={depositName}
          paymentInvoiceId={paymentInvoiceId}
          amount={depositAmount}
          refetchListData={refetchSettlementDepositList}
          settlementDepositData={settlementDepositData?.list}
          type="deposit"
          currency={currency}
        />
      )}
    </Box>
  );
}

export default DepositTable;
