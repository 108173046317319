import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import { TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { validateUEN } from "@sellernote/_shared/src/utils/common/validation";
import { checkIfTradingStatementCanBeSendToUser } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";

function UserInvoiceButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  setShowsSuccessSnackBar,
  setShowsErrorSnackBar,
  bidDetailData,
  invoiceState,
  getExchangeRateForRequest,
  requestButtonDisabledValue,
  isIssuedInvoice,
  setErrorMessage,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: (isTemporary: boolean) => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  setShowsSuccessSnackBar: (value: React.SetStateAction<boolean>) => void;
  setShowsErrorSnackBar: (value: React.SetStateAction<boolean>) => void;
  bidDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  getExchangeRateForRequest: (exchangeRateData: ExchangeRate[]) => number;
  requestButtonDisabledValue: boolean;
  isIssuedInvoice: boolean;
  setErrorMessage: (value: string) => void;
}) {
  const queryClient = useQueryClient();

  const invoiceId =
    sessionStorage.getItem("invoiceId") !== "none"
      ? Number(sessionStorage.getItem("invoiceId"))
      : undefined;

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { mutate: sendUserInvoice } = ADMIN_FILE_QUERY.useSendUserInvoice({
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
  });

  const isExport = !bidDetailData.isImport;

  const handleUserInvoiceRequestClick = useCallback(() => {
    if (
      bidDetailData.locale === "SG" &&
      !validateUEN(invoiceState.userBRN).isValid
    ) {
      setErrorMessage("정상적인 싱가포르 사업자등록번호가 아닙니다.");
      setShowsErrorSnackBar(true);

      return;
    }

    if (invoiceState.userBRN.length !== 10) {
      setErrorMessage("정상적인 사업자등록번호가 아닙니다.");
      setShowsErrorSnackBar(true);

      return;
    }

    if (invoiceId) {
      editInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: ({ data }) => {
            const invoiceId = data.id;
            sendUserInvoice(
              {
                pathParams: { invoiceId },
                invoiceType,
                exchangeRate: getExchangeRateForRequest(
                  invoiceState.invoiceExchangeRateList
                ),
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(
                    ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                      invoiceId,
                    })
                  );

                  if (isExport) {
                    queryClient.invalidateQueries(
                      TRELLO_BID_QUERY_KEY_GEN.getExportTrelloDetail({
                        bidId: bidDetailData.id,
                      })
                    );
                  }
                },

                onError({ response }) {
                  handleRequestError(response);
                },
              }
            );
          },

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: ({ data }) => {
            const invoiceId = data.id;
            sendUserInvoice(
              {
                pathParams: { invoiceId },
                invoiceType,
                exchangeRate: getExchangeRateForRequest(
                  invoiceState.invoiceExchangeRateList
                ),
              },
              {
                onSuccess: () => {
                  sessionStorage.setItem("invoiceId", data.id.toString());

                  if (isExport) {
                    queryClient.invalidateQueries(
                      TRELLO_BID_QUERY_KEY_GEN.getExportTrelloDetail({
                        bidId: bidDetailData.id,
                      })
                    );
                  }
                },

                onError({ response }) {
                  handleRequestError(response);
                },
              }
            );
          },

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    }
  }, [
    bidDetailData.locale,
    bidDetailData.id,
    invoiceState.userBRN,
    invoiceState.invoiceExchangeRateList,
    invoiceId,
    setErrorMessage,
    setShowsErrorSnackBar,
    editInvoice,
    getInvoiceDataForRequest,
    invoiceType,
    sendUserInvoice,
    getExchangeRateForRequest,
    queryClient,
    isExport,
    handleRequestError,
    saveInvoice,
  ]);

  return (
    <Button
      disabled={
        saveInvoiceLoading ||
        editInvoiceLoading ||
        checkIfTradingStatementCanBeSendToUser(
          bidDetailData.projectStatus,
          bidDetailData.locale,
          bidDetailData.isImport
        ) ||
        requestButtonDisabledValue ||
        isIssuedInvoice
      }
      variant="contained"
      color="secondary"
      onClick={handleUserInvoiceRequestClick}
    >
      {saveInvoiceLoading || editInvoiceLoading ? (
        <CircularProgress />
      ) : (
        "고객사 발송"
      )}
    </Button>
  );
}

export default UserInvoiceButton;
