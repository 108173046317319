import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { Button, CircularProgress } from "@mui/material";
import { Modal } from "antd";
import { AxiosResponse } from "axios";

import { RequestInvoiceData } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminFile";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { ResponseFailureInfo } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { validateUEN } from "@sellernote/_shared/src/utils/common/validation";
import { checkIfTradingStatementCanBeSendToCustomsBroker } from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import {
  checkHasPurchasePartner,
  checkPurchasePartnerDomainIsCustoms,
} from "@sellernote/_shared/src/utils/forwarding/trello";

function CustomsInvoiceButton({
  invoiceType,
  getInvoiceDataForRequest,
  handleRequestError,
  setSuccessMessage,
  setShowsSuccessSnackBar,
  setErrorMessage,
  setShowsErrorSnackBar,
  bidDetailData,
  invoiceState,
  getExchangeRateForRequest,
  requestButtonDisabledValue,
  isIssuedInvoice,
  isIssuedBidInvoice,
}: {
  invoiceType: InvoiceType;
  getInvoiceDataForRequest: (isTemporary: boolean) => RequestInvoiceData;
  handleRequestError: (
    response: AxiosResponse<ResponseFailureInfo, any> | undefined
  ) => void;
  setSuccessMessage: (value: React.SetStateAction<string>) => void;
  setShowsSuccessSnackBar: (value: React.SetStateAction<boolean>) => void;
  setErrorMessage: (value: React.SetStateAction<string>) => void;
  setShowsErrorSnackBar: (value: React.SetStateAction<boolean>) => void;
  bidDetailData: TrelloBidDetail;
  invoiceState: ApplyBidFormData;
  getExchangeRateForRequest: (exchangeRateData: ExchangeRate[]) => number;
  requestButtonDisabledValue: boolean;
  isIssuedInvoice: boolean;
  isIssuedBidInvoice: boolean;
}) {
  const queryClient = useQueryClient();

  const invoiceId =
    sessionStorage.getItem("invoiceId") !== "none"
      ? Number(sessionStorage.getItem("invoiceId"))
      : undefined;

  const { mutate: saveInvoice, isLoading: saveInvoiceLoading } =
    ADMIN_FILE_QUERY.useSaveInvoice();

  const { mutate: editInvoice, isLoading: editInvoiceLoading } =
    ADMIN_FILE_QUERY.useEditInvoice();

  const { mutate: sendCustomsInvoice } = ADMIN_FILE_QUERY.useSendCustomsInvoice(
    {
      bidId: bidDetailData.id,
      documentMailingFlag: bidDetailData.management.documentMailingFlag,
      setShowsSuccessSnackBar,
      setShowsErrorSnackBar,
      setSuccessMessage,
      setErrorMessage,
    }
  );

  const requestCustomsInvoice = useCallback(() => {
    if (invoiceId) {
      editInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: () => {
            sendCustomsInvoice(
              {
                invoiceType,
                exchangeRate: getExchangeRateForRequest(
                  invoiceState.invoiceExchangeRateList
                ),
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries(
                    ADMIN_BID_QUERY_KEY_GEN.getAdminInvoice({
                      invoiceId,
                    })
                  );
                },

                onError({ response }) {
                  handleRequestError(response);
                },
              }
            );
          },

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    } else {
      saveInvoice(
        {
          invoiceData: getInvoiceDataForRequest(false),
          isTemporary: false,
          invoiceType,
        },
        {
          onSuccess: ({ data }) => {
            sendCustomsInvoice(
              {
                invoiceType,
                exchangeRate: getExchangeRateForRequest(
                  invoiceState.invoiceExchangeRateList
                ),
              },
              {
                onSuccess: () => {
                  sessionStorage.setItem("invoiceId", data.id.toString());
                },

                onError({ response }) {
                  handleRequestError(response);
                },
              }
            );
          },

          onError: ({ response }) => {
            handleRequestError(response);
          },
        }
      );
    }
  }, [
    editInvoice,
    getExchangeRateForRequest,
    getInvoiceDataForRequest,
    handleRequestError,
    invoiceId,
    invoiceState.invoiceExchangeRateList,
    invoiceType,
    queryClient,
    saveInvoice,
    sendCustomsInvoice,
  ]);

  const { mutate: changeCustomsPaymentFlag } =
    TRELLO_BID_QUERY.useChangeCustomsPaymentFlag(bidDetailData.id);

  const handleCustomsPartnerModalButtonClick = useCallback(
    (flag: boolean) => {
      changeCustomsPaymentFlag(
        {
          customsPaymentFlag: flag,
        },
        {
          onSuccess: () => {
            requestCustomsInvoice();
          },

          onError: () => {
            setErrorMessage("물류비 대납변경에 실패했습니다.");
            setShowsErrorSnackBar(true);
          },
        }
      );
    },
    [
      changeCustomsPaymentFlag,
      requestCustomsInvoice,
      setErrorMessage,
      setShowsErrorSnackBar,
    ]
  );

  const handleCustomsInvoiceRequestClick = useCallback(() => {
    if (
      bidDetailData.locale === "SG" &&
      !validateUEN(invoiceState.userBRN).isValid
    ) {
      setErrorMessage("정상적인 싱가포르 사업자등록번호가 아닙니다.");
      setShowsErrorSnackBar(true);

      return;
    }
    if (
      bidDetailData.locale === "SG" &&
      !validateUEN(invoiceState.userBRN).isValid
    ) {
      setErrorMessage("정상적인 싱가포르 사업자등록번호가 아닙니다.");
      setShowsErrorSnackBar(true);

      return;
    }

    if (invoiceState.userBRN.length !== 10) {
      setErrorMessage("정상적인 사업자등록번호가 아닙니다.");
      setShowsErrorSnackBar(true);

      return;
    }

    if (!bidDetailData.management.documentMailingFlag) {
      return Modal.confirm({
        okText: "확인",
        cancelText: "취소",
        closable: true,
        content: "통관서류 및 거래명세서가 고객 이메일로 발송됩니다.",
        onOk: () => {
          requestCustomsInvoice();
        },
      });
    }

    if (!checkHasPurchasePartner(bidDetailData.accountPayables, "customs")) {
      setErrorMessage("관세사를 선택해주세요");
      setShowsErrorSnackBar(true);
      return;
    }

    if (checkPurchasePartnerDomainIsCustoms(bidDetailData.accountPayables)) {
      Modal.confirm({
        okText: "예",
        cancelText: "아니오",
        closable: true,
        content: "화주자체관세사가 고객의 물류비를 대납합니다",
        onOk: () => {
          handleCustomsPartnerModalButtonClick(true);
        },
        onCancel: (close) => {
          if (!close.triggerCancel) {
            handleCustomsPartnerModalButtonClick(false);
            close();
          }
        },
      });
    } else {
      requestCustomsInvoice();
    }
  }, [
    bidDetailData.accountPayables,
    bidDetailData.management.documentMailingFlag,
    handleCustomsPartnerModalButtonClick,
    requestCustomsInvoice,
    setErrorMessage,
    setShowsErrorSnackBar,
    bidDetailData.locale,
    invoiceState.userBRN,
  ]);

  return (
    <Button
      disabled={
        saveInvoiceLoading ||
        editInvoiceLoading ||
        checkIfTradingStatementCanBeSendToCustomsBroker(bidDetailData) ||
        requestButtonDisabledValue ||
        isIssuedInvoice ||
        isIssuedBidInvoice
      }
      variant="contained"
      color="success"
      onClick={handleCustomsInvoiceRequestClick}
    >
      {saveInvoiceLoading || editInvoiceLoading ? (
        <CircularProgress />
      ) : (
        "관세사 발송"
      )}
    </Button>
  );
}

export default CustomsInvoiceButton;
