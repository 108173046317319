import moment from "moment";

/** form yyyy/mm/dd */
export const formTimeStamp = (date: any) => {
  return moment(date).format("YYYY-MM-DD");
};

// 파일 확장자 추출하기
export const getExtensionOfFilename = (filename: string) => {
  const fileLen = filename.length;
  const lastDot = filename.lastIndexOf(".");
  const fileExt = filename.substring(lastDot, fileLen).toLowerCase();
  return fileExt;
};
/**
 *object deepClone funtion
 * @param obj getFileData에서 나온 excelData
 */
export const objectDeepClone = (obj: any) => {
  const clone: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const i in obj) {
    if (typeof obj[i] === "object" && obj[i] != null) {
      clone[i] = objectDeepClone(obj[i]);
    } else {
      clone[i] = obj[i];
    }
  }
  return clone;
};
/**
 * 날짜의 차이를 구해주는 함수
 * @param text "2020-04-20T17:40:00+09:00
 */
export const timeCheck = (text: string) => {
  const now = new Date();
  const currentDate = formTimeStamp(now);
  return moment(currentDate, "YYYY-MM-DD").diff(text, "day");
};
/**
 * 숫자 천단위마다 , 찍어주는 함수
 * @param inputNumber
 */
export const numberFormat = (inputNumber: any) => {
  if (inputNumber === null || inputNumber === undefined) {
    return 0;
  }
  return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * 숫자 배열을 요일로 바꾸어주는 함수
 * @param value "숫자 배열"
 */
export const dayCheck = (value?: number[]) => {
  if (!value) {
    return "";
  }
  const stringDay = [];
  if (value[0] === 1) {
    stringDay[0] = "월";
  }
  if (value[1] === 1) {
    stringDay[1] = "화";
  }
  if (value[2] === 1) {
    stringDay[2] = "수";
  }
  if (value[3] === 1) {
    stringDay[3] = "목";
  }
  if (value[4] === 1) {
    stringDay[4] = "금";
  }
  if (value[5] === 1) {
    stringDay[4] = "토";
  }
  if (value[6] === 1) {
    stringDay[4] = "일";
  }
  return stringDay;
};

export const transDate = (value: any) => {
  if (value) {
    const values = value.toDate();
    const year = values.getFullYear();
    let month = values.getMonth() + 1;
    let day = values.getDate();
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    const today = `${year}-${month}-${day}`;
    return today;
  }
  return "";
};

export const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  let day: any = date.getDate();
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  const today = `${year}-${month}-${day}`;
  return today;
};

export const getAfterMothDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  let month: any = date.getMonth() + 2;
  let day: any = date.getDate();
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  const today = `${year}-${month}-${day}`;
  return today;
};

const regexp = /^\d*[.]\d{2}$/;
/**
 * 소숫점 두자리 초과일때 입력 막는 함수
 * @param event 이벤트
 */
export const checkDecimalPoint = (event: any) => {
  const { value } = event.target;
  if (regexp.test(value)) {
    event.preventDefault();
  }
};
